const GetLocalStorageKey = (key) => {
    return window.localStorage.getItem(key);
};

const DeleteLocalStorageKey = (key) => {
    window.localStorage.removeItem(key);
};

const ClearLocalStorage = () => {
    window.localStorage.clear();
};

const SetLocalStorageKey = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

export {
    ClearLocalStorage,
    DeleteLocalStorageKey,
    GetLocalStorageKey,
    SetLocalStorageKey,
};
