import React, { useState } from "react"; 
import Fade from "@material-ui/core/Fade"; 
import { Link, Outlet } from "react-router-dom";
import Footer from "../Layout/Footer";  
import Loader from "../Component/Loader"; 
import { withRouter } from "../Utils/withRouter"; 

function Home(props) {
  let activeUrl = window.location.pathname.replace("/","");
  const [checked, setChecked] = useState(false);
  

  const ResponsiveNxaDropdown = () => {
    window.$("#navbarSupportedContentmob").removeClass("show");
  };

  return (
    <>
      <Fade in={checked} timeout={500}>
        <React.Fragment>
          <div className="home-page">
            <div className="top-bar">
              <div className="container">
                <div className="top-bar-inner">
                  <p className="mb-0 color-fff">
                    <span className="fw-700">Announcement Header: </span>
                    Nedbank &amp; 702 Business Ignite 2020 - Winner
                  </p>
                  <button className="btn-read-about ml-3">Read about it</button>
                </div>
              </div>
            </div>
            <header
              className={`header ${
                activeUrl === "/" || activeUrl === " "
                  ? "product-header-bg"
                  : "Pricing-header-bg"
              }`}
            >
              <div className="container">
                <div className="header-inner nxa-outer-header">
                  <nav className="navbar navbar-expand-lg d-none d-lg-flex">
                    <Link to="/" className="navbar-brand">
                      <img
                        src="./../assets/images/homepagelogo.svg"
                        alt="logo"
                      />
                    </Link>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto navigation">
                        <li className="nav-item">
                          <Link to="/login" className="nav-link">
                            Login
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="https://support.nxa.tel/portal/"
                          >
                            Support
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/pricing"
                            className="nav-link see-pricing-btn"
                          >
                            See Pricing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <nav className="navbar navbar-expand-lg d-none d-lg-flex">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto navigation-banner">
                        <li className="nav-item dropdown">
                          <a
                            className={`nav-link dropdown-toggle ${
                              [
                                "calls",
                                "meetings",
                                "message",
                                "nxa-docs",
                                "business-cloud",
                                "business-internet",
                              ].includes(activeUrl)
                                ? "active"
                                : ""
                            }`}
                            href={void 0}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Product
                            <i className="fas fa-chevron-down ml-2"></i>
                          </a>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "calls" ? "active" : ""
                              }`}
                              to="/calls"
                            >
                              Call
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "meetings" ? "active" : ""
                              }`}
                              to="/meetings"
                            >
                              Meet
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "message" ? "active" : ""
                              }`}
                              to="/message"
                            >
                              Message
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "nxa-docs" ? "active" : ""
                              }`}
                              to="/nxa-docs"
                            >
                              NxaDox
                            </Link>
                          </div>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${
                              ["demo", "pricing"].includes(activeUrl)
                                ? "active"
                                : ""
                            }`}
                            to="/pricing"
                          >
                            Pricing
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className={`nav-link ${
                              activeUrl === "why-nxatel" ? "active" : ""
                            }`}
                            to="/why-nxatel"
                          >
                            Why Nxatel
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>

                  {/* for mobile and ipad */}
                  <nav className="navbar navbar-expand-lg d-flex d-lg-none">
                    <Link className="navbar-brand" to="/">
                      <img
                        src="public/assets/images/homepagelogo.png"
                        alt="logo"
                      />
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContentmob"
                      aria-controls="navbarSupportedContentmob"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                      <span className="navbar-toggler-icon"></span>
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContentmob"
                    >
                      <ul className="navbar-nav ml-auto navigation">
                        <li className="nav-item dropdown">
                          <a
                            className={`nav-link dropdown-toggle ${
                              [
                                "calls",
                                "meetings",
                                "message",
                                "nxa-docs",
                                "business-cloud",
                                "business-internet",
                              ].includes(activeUrl)
                                ? "active"
                                : ""
                            }`}
                            href={void 0}
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Product
                          </a>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "calls" ? "active" : ""
                              }`}
                              to="/calls"
                              onClick={() => ResponsiveNxaDropdown()}
                            >
                              Call
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "meetings" ? "active" : ""
                              }`}
                              to="/meetings"
                              onClick={() => ResponsiveNxaDropdown()}
                            >
                              Meet
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "message" ? "active" : ""
                              }`}
                              to="/message"
                              onClick={() => ResponsiveNxaDropdown()}
                            >
                              Message
                            </Link>
                            <Link
                              className={`dropdown-item ${
                                activeUrl === "nxa-docs" ? "active" : ""
                              }`}
                              to="/nxa-docs"
                              onClick={() => ResponsiveNxaDropdown()}
                            >
                              NxaDox
                            </Link>
                          </div>
                        </li>
                        <li
                          className={`nav-item ${
                            ["demo", "pricing"].includes(activeUrl)
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            className={`nav-link`}
                            to="/pricing"
                            onClick={() => ResponsiveNxaDropdown()}
                          >
                            Pricing
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeUrl === "why-nxatel" ? "active" : ""
                          }`}
                        >
                          <Link
                            className={`nav-link`}
                            to="/why-nxatel"
                            onClick={() => ResponsiveNxaDropdown()}
                          >
                            Why Nxatel
                          </Link>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="https://support.nxa.tel/portal/"
                          >
                            Support
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  {/* end here */}
                </div>
              </div>
            </header>
            <Outlet />
            <Footer />
          </div>
        </React.Fragment>
      </Fade>
      <Loader />
    </>
  );
}

export default withRouter(Home);
