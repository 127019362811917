import React ,{useState,useEffect} from "react"; 
import Fade from "@material-ui/core/Fade";
function WhyNxatel() {
    const [checked, setChecked] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, 0); 
        setChecked(true);
    }, []);

    return (
        <>
            <Fade in={checked} timeout = {500}>
                <React.Fragment>
                <div className=" why-nxatel">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 d-block d-md-none">
                                <h1 className="font-31 text-center mt-5">
                                    Why Nxatel? tetst
                                </h1>
                                <div className="rectangle"></div>
                                <div className="nxa-banner-right">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-1.png"
                                        alt="1"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7">
                                <div className="why-nxa-banner mt-5">
                                    <h1 className="color-293F  d-none d-md-block">
                                        Why Nxatel?
                                    </h1>
                                    <h5 className="color-7C8087">
                                        Smart software for your small business.
                                        Features for every step of your customer
                                        journey. <br></br>
                                        <br></br>See how Nxatel became the Nxatel
                                        you love.
                                    </h5>
                                    <h5 className="color-293F">
                                        Customisable pricing and discounts that grow
                                        with your business and usage National &
                                        African Continent coverage across 10+
                                        countries for instant scale Carrier-grade
                                        quality without the carrier-grade fuss{" "}
                                        <br></br>
                                        Flexible connectivity options to suit your
                                        quality and security needs Best-in-breed,
                                        resilient tech and network architecture for
                                        more peace of mind Tools / APIs to
                                        streamline and automate carrier management &
                                        compliance Integrations with leading SBC
                                        platforms to provide quality<br></br> 24/7
                                        NOC support, plus Customer Success and
                                        Account Management
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 d-none d-md-block">
                                <div className="rectangle"></div>
                                <div className="nxa-banner-right">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-1.png"
                                        alt="1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="why-sec-2  ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="why-nxa-box d-flex">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-2.png"
                                        alt="1"
                                    />
                                    <div>
                                        <h6 className=" color-293F mb-2">
                                            Never miss a call
                                        </h6>
                                        <p className="font-16 color-7C8087">
                                            Never miss a potential customer by
                                            automatically sending a warm and
                                            welcoming text from your business asking
                                            how you can help, whether it’s after
                                            hours or you’re unable to reach the
                                            phone.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="why-nxa-box d-flex">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-3.png"
                                        alt="1"
                                    />
                                    <div>
                                        <h6 className=" color-293F mb-2">
                                            Better engage customers
                                        </h6>
                                        <p className="font-16 color-7C8087">
                                            Know more at every interaction so as
                                            soon as you pick up the phone or greet a
                                            customer, you see their name, photo,
                                            appointment history, any outstanding
                                            balances, appointment time preferences,
                                            and more!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="why-nxa-box d-flex">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-4.png"
                                        alt="1"
                                    />
                                    <div>
                                        <h6 className=" color-293F mb-2">
                                            Automate your communication
                                        </h6>
                                        <p className="font-16 color-7C8087">
                                            Automate and send your customers
                                            convenient texts for customised
                                            appointment reminders, birthday
                                            greetings, payment reminders and
                                            invitations to leave you a Facebook
                                            review!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-4">
                                <div className="why-nxa-box d-flex">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-2.png"
                                        alt="1"
                                    />
                                    <div>
                                        <h6 className="color-293F mb-2">
                                            Grow your business
                                        </h6>
                                        <p className="font-16 color-7C8087">
                                            Send a quick and easy text invitation to
                                            your customers to leave you a Facebook
                                            review and grow your online reputation
                                            for more future customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="why-nxa-box d-flex">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-3.png"
                                        alt="1"
                                    />
                                    <div>
                                        <h6 className="color-293F mb-2">
                                            End to End Encryption
                                        </h6>
                                        <p className="font-16 color-7C8087">
                                            Seven layers of security and redundant
                                            data centers ensure user protection and
                                            high availability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="why-nxa-box d-flex">
                                    <img
                                        src="./../assets/images/home-page-img/why-nxa-4.png"
                                        alt="1"
                                    />
                                    <div>
                                        <h6 className="color-293F mb-2">
                                            All-in-one
                                        </h6>
                                        <p className="font-16 color-7C8087">
                                            Locally Hosted and Scalable Globally
                                            Secure and reliable Integrates with apps
                                            Flexible connectivity Cost savings
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center one-app">
                            <h3 className="color-293F font-24 fw-700">
                                Start receiving and making calls, meeting people and
                                chatting all in one App.
                            </h3>
                        </div>
                    </div>
                </div> 
                </React.Fragment>
            </Fade> 
        </>
    );
}

export default WhyNxatel;
