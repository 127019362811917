import React from "react"; 
import swal from 'sweetalert'; 
import { withRouter } from "../../Utils/withRouter";
import { Link } from "react-router-dom"; 

function PageHeader(props) {
    const {activeClass} = props;

    const LogOut = () => {
        swal({ 
            text: "Are you sure want to Log out?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async(Cancel) => {
            if (Cancel) {
                localStorage.clear(); 
                sessionStorage.clear(); 
                props.navigate("/") 
            } else {
            }
        });
    };

    return (
        <nav className="navbar navbar-expand-lg">
            <Link
                to="/"
                className="navbar-brand" 
                style={{cursor:'pointer'}} 
            >
                <img src="./../assets/images/homepagelogo.png" alt="logo" />
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav ml-auto navigation">
                    {localStorage.token &&  
                    <li className={`nav-item ${activeClass && activeClass==="login"?"active":""}`}>
                        <Link
                            className={`nav-link see-pricing-btn`} 
                            to="#"
                            onClick={LogOut}
                        >
                            Logout
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>} 
                    {!localStorage.token &&  
                    <li className={`nav-item ${activeClass && activeClass==="login"?"active":""}`}>
                        <Link
                            to="/login"
                            className={`nav-link see-pricing-btn`} 
                        >
                            Login
                            <span className="sr-only">(current)</span>
                        </Link>
                    </li>}
                    {!localStorage.token && (
                        <li className={`nav-item ${!activeClass?"active":""}`}>
                            <Link
                                to="/signup"
                                className="nav-link" 
                            >
                                Sign Up
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
}

export default withRouter(PageHeader);
