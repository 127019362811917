import React, { Component } from 'react'; 

class Cloud extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                <div className="product-call-banner">
                    <h2>Comming Soon... </h2>
                </div> 
                
            </>
        );
    }
}

export default Cloud;