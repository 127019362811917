import React from "react";   
import { useNavigate } from "react-router-dom";
import { LINKEDIN_LOGIN_AND_SIGNUP } from "./Pages/LinkedIn/actions";
import { AppRouter } from "./Routes";  


const App = () => { 
  let url_string = window.location.href
  let navigate = useNavigate(); 
  if(url_string.includes("code=")){
      let newUrl = new URL(url_string); 
      const code = newUrl.searchParams.get("code"); 
      const state = newUrl.searchParams.get("state"); 
      LINKEDIN_LOGIN_AND_SIGNUP(code,state,navigate)
      return true
  } 

  return (
    <>  
      <AppRouter />
    </>
  );
}; 
 
export default (App);