import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

export const VideoAutoPlay = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        videoRef.current.pause();
      }
    }
  }, [isVisible]);

  return (
    <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
      <video width="100%" height="100%" controls loop ref={videoRef}>
        <source
          src="./../assets/videos/nxatel-manage-calls.mp4"
          type="video/mp4"
        />
      </video>
    </VisibilitySensor>
  );
};

class Call extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <div className="buisness-cloud-banner">
          <div className=" container">
            <div className="row">
              <div className="col-md-6">
                <div className="buisness-cloud-banner-text">
                  <h1 className="">
                    Upgrade your business phone system to a cloud PBX.​
                  </h1>
                  <h3 className="">
                    Set up your business phone system in minutes. Choose your
                    business number from over 20+ countries​
                  </h3>
                  <p>
                    One Provider for everything Easy to scale Work from Home
                    Ready. All your Business conversations in one place.​
                  </p>
                  <div className="col-md-12 btn-center-home ">
                    <Link
                      to="/pricing"
                      className="btn htw-learn-more mr-2" 
                    >
                      See Pricing
                    </Link>
                    <Link
                      to="/demo"
                      className="btn get-demo-red" 
                    >
                      Get your Demo
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="buisness-cloud-banner-img">
                  <img
                    src="./../assets/images/product-call-page-banner.png"
                    alt="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hybrid-work-section">
          <div className=" container">
            <div className="row">
              <h5>
                The complete communication system for growing your business.​
              </h5>
              <div className="col-md-6">
                <div className="hybrid-work-sec-left">
                  <img src="./../assets/images/hybird-work-img.png" alt="1" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="hybrid-work-sec-right">
                  <h2>BUILT FOR HYBRID WORK​</h2>
                  <p>
                    <b>Easy to use​</b>
                    <br />
                    Your business phone number available instantly.​
                  </p>
                  <p>
                    <b>Secure and reliable​</b>
                    <br />
                    Stay connected with a 99.999% uptime SLA and
                    <br />
                    enterprise-grade security.​​
                  </p>
                  <p>
                    <b>Never miss a call​</b>
                    <br></br>
                    Quickly route incoming calls with a virtual receptionist.​​
                  </p>
                </div>
                <div className="btn-center-home float-left">
                  <Link
                    to="/pricing"
                    className="btn htw-learn-more mr-2" 
                  >
                    See Pricing
                  </Link>
                  <Link
                    to="/demo"
                    className="btn get-demo-red" 
                  >
                    Get your demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="complete-communction-system manage-call-recording-cloud">
          <div className=" container">
            <div className="col-md-12">
              <h2 className="">
                Manage calls, voicemails, call recording and business SMS on any
                device.​
              </h2>
              <p>Built for non-technical business owners.​​</p>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="complete-communction-system-left calls-manage-calls-record-video">
                  <div className="make-dreamer-video-box">
                    <VideoAutoPlay />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="complete-communction-system-right">
                  <h4 className="">Mobile-ready​</h4>
                  <p>
                    ​Say hello! On the go​​
                    <br />
                    Take your business phone with you even
                    <br /> when you’re away from your office desk.​
                  </p>
                  <h4 className="mt-3">
                    Easily Switch between desktop​ and
                    <br /> mobile.
                  </h4>
                  <p>
                    ​Receive business calls, send SMS contacts,
                    <br /> and​ more on your Apple or Android
                    <br /> smartphone.​​​​
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="work-anywhere-together">
          <div className=" container">
            <div className="col-md-12">
              <h2 className="">Work Anywhere, Together​​​</h2>
              <h3>
                Keep it simple and stop juggling separate applications and
                <br /> get a single platform that has just what you need.​​
              </h3>
              <div className="btn-center-home ">
                <Link
                  to="/pricing"
                  className="btn htw-learn-more mr-2" 
                >
                  See Pricing
                </Link>
                <Link
                  to="/demo"
                  className="btn get-demo-red" 
                >
                  Get your demo
                </Link>
              </div>
            </div>
            <div className="work-anywhere-box-div">
              <div className="row">
                <div className="col-md-4">
                  <div className="work-anywhere-box">
                    <h5>Nxatel Mobile App​</h5>
                    <img src="./../assets/images/dwn-our-app.png" alt="1" />
                    <Link to="#" className="dwn-app-work-anyw">
                      Download our App ​
                      <img
                        src="./../assets/images/right-arrow-dwn.svg"
                        alt="1"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-anywhere-box">
                    <h5>Web App​</h5>
                    <img src="./../assets/images/dwn-web-app.png" alt="1" />
                    <Link to="#" className="dwn-app-work-anyw">
                      Access our Web App
                      <img
                        src="./../assets/images/right-arrow-dwn.svg"
                        alt="1"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-anywhere-box">
                    <h5>IP Phone​</h5>
                    <img src="./../assets/images/shop-for-ipdv.png" alt="1" />
                    <Link to="#" className="dwn-app-work-anyw">
                      Shop for IP Devices​
                      <img
                        src="./../assets/images/right-arrow-dwn.svg"
                        alt="1"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="work-anywhere-faq">
              <div id="accordion" className="work-anywork-fq-inner">
                <h3>Got questions about Nxatel?</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card fq-card-anywhere">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Can I use my existing business phone numbers?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card fq-card-anywhere">
                      <div className="card-header" id="headingTwo">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Can Nxatel work over Wi-Fi?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card fq-card-anywhere">
                      <div className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How do I set up Nxatel service?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card fq-card-anywhere">
                      <div className="card-header" id="heading4">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                            Do I need a special phone to use Nxatel?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse4"
                        className="collapse"
                        aria-labelledby="heading4"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card fq-card-anywhere">
                      <div className="card-header" id="heading5">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            What's the difference between Fixed landlines, PABX
                            and Nxatel?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse5"
                        className="collapse"
                        aria-labelledby="heading5"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card fq-card-anywhere">
                      <div className="card-header" id="heading6">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                            Does Nxatel offer month to month or annual plans?​
                            <span className="collapse-arrow">
                              <img
                                src="./../assets/images/faq-dwn-arrow.svg"
                                alt="1"
                              />
                            </span>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse6"
                        className="collapse"
                        aria-labelledby="heading6"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using
                            'Content here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="nxatel-free-seven-days business-cloud-free-day">
          <div className=" container">
            <div className="row">
              <div className="col-md-6">
                <div className="nxatel-free-seven-days-left">
                  <img
                    src="./../assets/images/try-free-seven-day-callpage.png"
                    alt="1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="nxatel-free-seven-days-right">
                  <h3 className="">
                    Try Nxatel for<br></br> free for 7 Days
                  </h3>
                  <p>
                    Plans start at R99 per user/per month No long-term
                    contracts. No hidden fees. No nonsense.
                  </p>
                  <p className="mt-4">
                    Ready to switch to a unified platform?​{" "}
                  </p>
                  <div className="btn-center-home ">
                    <Link
                      to="/pricing"
                      className="btn htw-learn-more mr-2" 
                    >
                      See Pricing
                    </Link>
                    <Link
                      to="/demo"
                      className="btn get-demo-red" 
                    >
                      Get your Demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Call;
