import { GetLocalStorageKey, ClearLocalStorage } from "./localStorageService";

class AuthService {
    constructor() {
        this.authenticated = true;
    }

    login(cb) {
        this.authenticated = true;
        cb();
    }

    getUserAccessToken() {
        return GetLocalStorageKey("token");
    }

    getUserDetail() {
        return localStorage.data
            ? JSON.parse(localStorage.data)
            : {};
    }

    getGuestUserDetail() {
        return JSON.parse(GetLocalStorageKey("guestDetail"));
    }

    logout() {;
        ClearLocalStorage();
    }

    isAuthenticated() {
        const isLoggedIn = this.getUserAccessToken();
        this.authenticated = isLoggedIn ? true : false;
        return this.authenticated;
    }
}
export default new AuthService();
