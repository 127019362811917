import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import GoogleLogin from "react-google-login";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import { store } from "../../Services";
import {
  AccountSummary,
  LoginFxn,
} from "../../Services/Actions/LoginSignupActions";
import authService from "../../Utils/authService"; 
import { withRouter } from "../../Utils/withRouter"; 
import { LINKEDIN_WEB_LOGIN_URL } from "../LinkedIn/parameters";
import { 
  NavigationPage,
  REACT_APP_GOOGLE_APP_ID, 
} from "../../Utils/Common"; 
import swal from "sweetalert";

const initialState = {
  email: "",
  password: "",
  click: false,
  openForgotModal: false,
  fbLogin: false,
  accessCode: "",
  accessState: "",
  checked: false, 
  login_credentials: {}, 
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ checked: true });
    if (authService.isAuthenticated()) {
      let response = await AccountSummary(store.dispatch);
      if (response.code === 1) {
        let { data: { status, user_status } = {} } = response || {};
        NavigationPage(status, user_status);
      } 
    }  
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13) { 
      this.submitHandler();
    }
  };

  _facebookLogin = () => {
    console.log("clicked")
    window.FB.getLoginStatus((response) => {
      console.log("response",response)
      if (response.status == "connected") {
        this.getCurrentFacebookUserInfo(response);
      } else {
        window.FB.login(
          (response) => {
            console.log(" else login response",response)
            // if (response.authResponse) {
              this.getCurrentFacebookUserInfo(response);
            // }
          },
          { scope: "email" }
        );
      }
    });
  };

  getCurrentFacebookUserInfo() { 
    window.FB.api("/me?fields=id,email,name", (userInfo) => { 
      this._loginViaSocialSignup(userInfo?.email);
      if (Object.keys(userInfo).length) { 
        window.FB.logout((response) => {
          console.log("logout response",response)
          // Person is now logged out
        });
      }
    });
  }

  responseGoogle = (data) => {
    this._loginViaSocialSignup(data?.profileObj?.email);
  };

  _loginViaSocialSignup = async (email) => {
    if (email) {
      const data = {
        email,
        request_from: "web",
        social: "Y",
        token: true, 
      };
      const response = await LoginFxn(data, store.dispatch);
      if(response){
        this.setState({ initialState }); 
      } 
    }
  }  

  submitHandler = async () => {
    const { email, password } = this.state;
    let data = { email, password, request_from: "web", token: true };
    if (this.validator.allValid()) {
      this.setState({ click: true });
      const response = await LoginFxn(data, store.dispatch); 
      if (response) { 
        this.setState({ click: false }); 
      } 
    } else {
      this.setState({ click: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  }; 

  closeForgotModal = () => {
    this.setState({ openForgotModal: false });
  };


  render() { 
    const { click, email, password, checked, login_credentials } = this.state;
    return (
      <>
        <Fade in={checked} timeout={500}>
          <div className="row">
            <div className="col-md-6">
              <div className="sign-left-part sign-left-first-page  pt-5">
                <h1 className="font-36 color-191b fw-700 text-center ">
                  Welcome Back!
                </h1>
                <img src="./../assets/images/signup/login-img.png" alt="1" />
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-3">
              <div className="sign-right-part pt-5">
                <h1 className="font-36 color-191b fw-700 text-center mb-5">
                  Sign In
                </h1>
                <div className="form-group mb-3 mb-1">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Email"
                    aria-label="full Name"
                    aria-describedby="basic-addon1"
                    name="email"
                    value={email}
                    onChange={(e) =>
                      this.setState({
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  {this.validator.message(
                    "Email",
                    email,
                    "required|email|max:50,string"
                  )}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="password"
                    className="form-control "
                    placeholder="Password"
                    aria-label="full Name"
                    aria-describedby="basic-addon1"
                    name="password"
                    value={password}
                    onChange={(e) =>
                      this.setState({
                        [e.target.name]: e.target.value,
                      })
                    }
                    onKeyDown={(e) => this.onEnterPress(e)}
                  />
                  {this.validator.message(
                    "Password",
                    password,
                    "required|min:6,string"
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-new-sign bg-back  mr-3"
                    onClick={() => this.props.navigate("/")}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-new-sign"
                    onClick={this.submitHandler}
                    disabled={click}
                  >
                    {click ? "Loading..." : "Sign in"}
                  </button>
                </div>

                <h6 className="color-191b font-18 text-center mt-3 mb-3 fw-400">
                  or Sign in with
                </h6>
                <div className="d-flex login-with-icons justify-content-center">
                  <img
                    src="./../assets/images/signup/facebook.png"
                    alt="1"
                    onClick={this._facebookLogin}
                    style={{ cursor: "pointer" }}
                  />
                  <GoogleLogin
                    clientId={REACT_APP_GOOGLE_APP_ID}
                    render={(renderProps) => (
                      <a
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <img
                          src="./../assets/images/signup/google.png"
                          alt="1"
                        />
                      </a>
                    )}
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                  <a href={LINKEDIN_WEB_LOGIN_URL}>
                    <img src="./../assets/images/signup/linkedin.png" alt="1" />
                  </a>
                </div>
                <div className="form-check text-center mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label color-191b font-14 fw-700"
                    for="exampleCheck1"
                  >
                    Stay signed with
                  </label>
                </div>
                <p className="by-sign-in font-10 color-ABB7">
                  By signing in, you agree to the Terms of Service and Privacy
                  Policy.
                </p>
                <p className="text-center" style={{ cursor: "pointer" }}>
                  <Link
                    to="/forgot-password"
                    className="font-10 color-6666 fw-500"
                  >
                    Forgot Your Password?
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to="/forgot-email"
                    className="font-10 color-6666 fw-500"
                  >
                    Forgot Your Email?
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Fade> 
      </>
    );
  }
}

export default withRouter(Login);
