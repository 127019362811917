import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import OtpInput from "react-otp-input";
import { withRouter } from "../../Utils/withRouter";
import {
  FetchForgotPassword,
  VerifyForgotPassword,
} from "../../Services/Actions/LoginSignupActions";
import { store } from "../../Services";
import PageHeader from "./PageHeader";
import Loader from "../../Component/Loader";
import Footer from "../../Layout/Footer"; 

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      clicked: false,
      otpSent: false,
      verified: false,
      otp: "",
    };
    this.validator = new SimpleReactValidator();
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  SubmitHandler = async () => {
    this.setState({ clicked: true });
    if (!this.validator.allValid()) {
      this.setState({ clicked: false });
      this.validator.showMessages();
      this.forceUpdate();
      return null;
    }
    const { email, phone } = this.state;
    const response = await FetchForgotPassword(
      { email, phone: phone.replace(/[^0-9]/g, "") },
      store.dispatch
    );
    if (response === 1) {
      this.setState({ otpSent: true, clicked: false });
    } else {
      this.setState({ clicked: false });
    }
  };

  VerifyHandler = async () => {
    this.setState({ clicked: true });
    if (!this.validator.allValid()) {
      this.setState({ clicked: false });
      this.validator.showMessages();
      this.forceUpdate();
      return null;
    }
    const { otp, email, phone } = this.state;
    const response = await VerifyForgotPassword(
      { otp, email, phone: phone.replace(/[^0-9]/g, "") },
      store.dispatch
    );
    if (response === 1) {
      this.setState({ clicked: false, verified: true });
    } else {
      this.setState({ clicked: false });
    }
  };

  ResetHandler = async () => {
    this.setState({ clicked: true });
    if (!this.validator.allValid()) {
      this.setState({ clicked: false });
      this.validator.showMessages();
      this.forceUpdate();
      return null;
    }
    const { email, phone, password, otp } = this.state;
    const response = await VerifyForgotPassword(
      { otp, email, phone: phone.replace(/[^0-9]/g, ""), password },
      store.dispatch
    );
    if (response === 1) {
      this.setState({ clicked: false, otp: "", phone: "", email: "" }, () => {
        this.props.navigate("/login");
      });
    } else {
      this.setState({ clicked: false });
    }
  };

  render() {
    const { email, phone, clicked, otpSent, verified, otp, password } =
      this.state;
    this.validator.purgeFields();
    return (
      <>
        <div className="forget-password-type signup-type">
          <div className="container">
            <PageHeader activeClass="login" />
            <div className="row">
              <div className="col-md-6">
                <div className="sign-left-part sign-left-part-vector-img sign-left-align-center pt-5">
                  <img src="./../assets/images/signup/2.png" alt="1" />
                </div>
              </div>
              <div className="col-md-1"></div>

              {!otpSent && (
                <div className="col-md-5">
                  <div className="sign-right-part pt-5">
                    <h1 className="font-36 color-191b fw-700 text-center mt-4">
                      Forgot Password
                    </h1>
                    <p className="color-566E font-16 mb-4 text-center">
                      Don't worry. Resetting your password is easy, just tell us
                      the email address and Mobile Number you registered with
                      Nxatel
                    </p>
                    <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <div className="form-group mb-3 mb-1">
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={this.onChangeHandler}
                          />
                          {this.validator.message(
                            "Email",
                            email,
                            "required|email"
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="phone"
                            className="form-control "
                            placeholder="Phone"
                            name="phone"
                            value={phone}
                            onChange={this.onChangeHandler}
                          />
                          {this.validator.message("Phone", phone, "required")}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center col-md-10 offset-md-1 p-0 otp-verify-btn">
                      <button
                        type="button"
                        className="btn btn-new-sign bg-back  mr-3"
                        onClick={() => this.props.navigate("/login")}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-new-sign"
                        onClick={this.SubmitHandler}
                        disabled={clicked}
                      >
                        {clicked ? "Please wait..." : "Send OTP"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {otpSent && !verified && (
                <div className="col-md-5">
                  <div className="sign-right-part pt-5">
                    <h1 className="font-36 color-191b fw-700 text-center mt-4">
                      Verify Phone Number
                    </h1>
                    <p className="color-566E font-16 mb-4 text-center">
                      Please enter the OTP you have Recieved as an SMS
                    </p>
                    <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <div className="form-group otp  justify-content-center">
                          <div className="d-flex align-items-center otp-input-right login-otp">
                            <OtpInput
                              name="otp"
                              onChange={(otp) => this.setState({ otp })}
                              numInputs={4}
                              separator={<span>&nbsp;</span>}
                              value={otp}
                              className="form-control h-100"
                            />
                          </div>
                          {this.validator.message("otp", otp, "required")}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center col-md-10 offset-md-1 p-0 otp-verify-btn">
                      <button
                        type="button"
                        className="btn btn-new-sign bg-back  mr-3"
                        onClick={() => this.setState({ otpSent: false })}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-new-sign"
                        onClick={this.VerifyHandler}
                        disabled={clicked}
                      >
                        {clicked ? "Please wait..." : "Verify and Reset"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {otpSent && verified && (
                <div className="col-md-5">
                  <div className="sign-right-part pt-5">
                    <h1 className="font-36 color-191b fw-700 text-center mt-4">
                      Change Password
                    </h1>
                    <p className="color-566E font-16 mb-4 text-center">
                      Set a new password, and sign in
                    </p>
                    <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <div className="form-group mb-3 mb-1">
                          <input
                            type="password"
                            className="form-control "
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={this.onChangeHandler}
                          />
                          {this.validator.message(
                            "Password",
                            password,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center col-md-10 offset-md-1 p-0 otp-verify-btn">
                      <button
                        type="button"
                        className="btn btn-new-sign bg-back  mr-3"
                        onClick={() =>
                          this.setState({ otpSent: false, verified: false })
                        }
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-new-sign"
                        onClick={this.ResetHandler}
                        disabled={clicked}
                      >
                        {clicked ? "Please wait..." : "Save and Sign-In"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Loader />
        <Footer />
      </>
    );
  }
}

export default withRouter(ForgotPassword);
