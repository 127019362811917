import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator"; 
import Fade from "@material-ui/core/Fade"; 
import { withRouter } from "../../Utils/withRouter";  
import { store } from "../../Services";
import { FetchResetPassword } from "../../Services/Actions/LoginSignupActions";

const initialState = {
  newpassword: "",
  confirmpassword: "",
  otp: "",
  click: false,
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({
      messages: {
        in: "Passwords need to match!",
      },
    });
  }

  componentDidMount() {
    let path = window.location.pathname.split("/");
    let otp = path && path[2];
    this.setState({ otp });
  }

  onEnterPress = (e) => {
    if (e.keyCode === 13) {
      this.ChangePasswordHandler();
    }
  };

  ChangePasswordHandler = async () => {
    const { newpassword, confirmpassword, otp } = this.state;
    this.setState({ click: true });
    if (this.validator.allValid()) {
      let data = { newpassword, confirmpassword, otp };
      let response = await FetchResetPassword(data,store.dispatch);
      this.setState({ click: false });
      if (response === 1) { 
        this.props.navigate("/login") 
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  onchangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    this.validator.purgeFields();
    const { newpassword, confirmpassword, otp, click } = this.state;
    return (
      <>
        <Fade in={true} timeout={500}>
          <div className="row">
            <div className="col-md-5">
              <div className="sign-left-part sign-left-first-page  pt-5">
                <img src="./../assets/images/signup/login-img.png" alt="1" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="sign-right-part pt-5">
                <h1 className="font-36 color-191b fw-700 text-center mb-5">
                  Welcome, You can reset your password
                </h1>
                <div className="welcome-complete-reg">
                  <div className="form-group mb-3 mb-1">
                    <input
                      type="password"
                      name="newpassword"
                      className="form-control"
                      placeholder="New Password"
                      value={newpassword}
                      onChange={this.onchangeHandler}
                    />
                    {this.validator.message(
                      "New Password",
                      newpassword,
                      `required`
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      name="confirmpassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={confirmpassword}
                      onChange={this.onchangeHandler}
                    />
                    {this.validator.message(
                      "Confirm Password",
                      confirmpassword,
                      `required|in:${newpassword}`
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="otp"
                      className="form-control"
                      placeholder="OTP"
                      value={otp}
                      onChange={this.onchangeHandler}
                    />
                    {this.validator.message("OTP", otp, `required`)}
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    <button
                      type="button"
                      className="btn btn-new-sign bg-back  mr-3"
                      onClick={() => window.location.href= `${process.env.REACT_APP_WEBSITE_URL}`}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-new-sign"
                      onClick={this.ChangePasswordHandler}
                      onKeyDown={(e) => this.onEnterPress(e)}
                      disabled={click}
                    >
                      {click ? "Loading..." : "Reset"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </>
    );
  }
}

export default withRouter(ResetPassword);
