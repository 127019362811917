import React from "react";
import { connect} from "react-redux";
import "./loader.css";

const Loader = (props) => {   
  return (
    <div id="preloader" className={`${props.loader ? "show" : "hide"}`}>
      <div id="loader"></div>
    </div>
  );
};

const mapStateToProps = state => ({ 
  loader:state?.users?.loader??false
});

export default connect(mapStateToProps)(Loader); 
