import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";  
import { useDispatch, connect } from "react-redux"; 
import { toastr } from "../../Services/Actions/userActions";
import { store } from "../../Services";

function Alerts(props) {
  const [show, setShow] = useState(false); 
  const dispatch = useDispatch()

  let {toastrData:{duration,message,type,vertical,horizontal}} = props;
   
  vertical = vertical ? vertical : "top";
  horizontal = horizontal ? horizontal : "right";

  useEffect(() => {
    if (type) {
      setShow(true);
      setTimeout(() => {
        ClearAlert();
      }, duration + 500);
    }
  }, [type, duration]);

  const ClearAlert = () => {
    setShow(false);
    dispatch(toastr({}),store.dispatch) 
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={duration}
      onClose={ClearAlert}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <Alert onClose={ClearAlert} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
} 

const mapStateToProps = (state) => ({
  toastrData: state?.users?.toastr??{},
});

export default connect(mapStateToProps)(Alerts); 
