 
import { store } from "../../Services";
import { AccessViaLinkedin, LoginFxn } from "../../Services/Actions/LoginSignupActions";
import { IsSocialSignupFrom, SocialSignup } from "../../Services/Actions/userActions"; 
import swal from "sweetalert" 



export const LINKEDIN_LOGIN_AND_SIGNUP = async (authorization_code, state,navigate) => {
    if (state === "Login") {
        LINKEDIN_LOGIN(authorization_code,navigate);
    } else {
        LINKEDIN_SIGNUP(authorization_code,navigate); 
    }
};

const LINKEDIN_SIGNUP = async(authorization_code,navigate) => {
    let request = {
        code: authorization_code,
        redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}signup`,
        type: "signup",
    };
    let response = await AccessViaLinkedin(request,store.dispatch);
    if (response.code === 1) {
        const { firstname, lastname, email } = response.response; 
            SocialSignup({ name: `${firstname} ${lastname}`, email },store.dispatch)  
            IsSocialSignupFrom({ from: "nxa-page", with: "linkedin" },store.dispatch)  
            navigate('/signup') 
    } else {
        swal({
            title: "Error!",
            text: response.response,
            icon: "error",
        });
    }
};

const LINKEDIN_LOGIN = async(authorization_code,navigate) => {
    let request = {
        code: authorization_code,
        redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}login`,
        type: "login",
    };
    let response = await AccessViaLinkedin(request,store.dispatch);
    if (response.code === 1) {
        let credentials = {
            request_from: "web",
            social: "Y",
            email: response.response,
            token: true,
        };
        const linkedinLogin = await LoginFxn(credentials,store.dispatch);
        if(linkedinLogin.ResponseCode === "0"){
            navigate("/login")
            swal({
                title: "Error!",
                text: linkedinLogin.ResponseText, 
                icon: "error",
            });
        }
    } else {
        swal({
            title: "Error!",
            text: response.response,
            icon: "error",
        });
    }
};
