 
import React from 'react';
import { Link } from "react-router-dom";  


function Footer(props) { 
  return (
    <div className="home-page">
        <footer className="footer-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="footer-nav">
                      <ul className="">
                        <h6 className="font-20 fw-700">Sales</h6>

                        <li>
                          <Link to="#">Contact Sales</Link>
                        </li>
                        <li>
                          <Link to="#">0876542190</Link>
                        </li>
                        <li>
                          <Link to="/pricing">Plan & Pricing</Link>
                        </li>
                        <li>
                          <Link to="/demo">Request a Demo</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="footer-nav">
                      <ul className="">
                        <h6 className="font-20 fw-700">Products</h6>

                        <li>
                          <Link to="/calls">Phone</Link>
                        </li>
                        <li>
                          <Link to="/meetings">Meeting</Link>
                        </li>
                        <li>
                          <Link to="/message">Message</Link>
                        </li>
                        <li>
                          <Link to="/business-cloud">Cloud Storage</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="footer-nav">
                      <ul className="">
                        <h6 className="font-20 fw-700">Company </h6>
                        <li>
                          <Link to="/why-nxatel">Why Nxatel</Link>
                        </li>
                        <li>
                          <Link to="#">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="#">Join Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="about-nexa-footer">
                      <a className="footer-logo">
                        <img
                          src="./../assets/images/homepagelogo.png"
                          alt="logo"
                        />
                      </a>
                      <p>
                        Nxatel, Pty Ltd. is a Modern communication platform, for
                        Small and Medium businesses, It’s easy to set-up you
                        just need 3 minutes and you gain access to cloud phone
                        system, virtual meetings, chats, SMS’s and cloud storage
                        solutions all in single App.
                      </p>
                      <h3>Be in the know</h3>
                      <div className="footer-emial-input-div">
                        <input
                          className="form-control"
                          placeholder="email"
                          type="text"
                        />
                        <a className="sign-up-now-footer" href={void 0}>
                          Sign Up Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-5 copy-right-row">
                  <div className="col-md-4 d-flex align-self-center">
                    <h6 className="font-16 fw-700">Copyright@Nxatel.2020</h6>
                  </div>
                  <div className="col-md-5 d-flex align-self-center">
                    <ul className="nav  bottom-nav">
                      <li>
                        <a
                          href="https://support.nxa.tel/portal/"
                          className="font-16 fw-700"
                        >
                          Support
                        </a>
                      </li>
                      <li>
                        <Link to="/privacy" className="font-16 fw-700">
                          Privacy
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="font-16 fw-700">
                          Terms
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <ul className=" nav social-bottom ">
                      <li>
                        <a href={void 0}>fb.</a>
                      </li>
                      <li>
                        <a href={void 0}>ig.</a>
                      </li>
                      <li>
                        <a href={void 0}>tw.</a>
                      </li>
                      <li>
                        <a href={void 0}>yt.</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
  );
}

export default Footer;

