 import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import userReducer from "./userReducer"

const persistConfig = {key:'root',storage,whitelist:[]};

const rootReducer = combineReducers({
    users:userReducer, 
});

export default persistReducer(persistConfig,rootReducer);
