import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Layout/Footer";
import { connect } from "react-redux";
import { store } from "../../Services";
import Login from "./Login";
import Signup from "./Signup";
import Registration from "./Registration";
import {
  HomePageSubPages,
  HomePageSubTabs,
} from "../../Services/Actions/userActions";
import Loader from "../../Component/Loader";
import ResetPassword from "./ResetPassword";

const InitialState = { tab: "login", subTab: "" };
class LoginSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = InitialState;
  }

  componentDidMount() { 
    const {
      nxatel: { sub_pages, sub_tabs },
    } = this.props;
    if (sub_pages && ["login", "join-meeting", "sign-up"].includes(sub_pages)) {
      if (sub_pages === "login") {
        this.setState({
          tab: "login",
        });
      } else if (sub_pages === "sign-up") {
        this.setState({ tab: "sign-up", subTab: sub_tabs ?? "" });
      }
    }
  }

  selectTab = (value) => {
    this.setState({ tab: value, subTab: "" }, () => {
      HomePageSubPages(value,store.dispatch);
    });
  };

  selectSubTab = (subTab) => {
    this.setState({ subTab }, () => {
      HomePageSubTabs(subTab,store.dispatch);
    });
  };

  render() {
    const { subTab } = this.state;
    const activeUrl = window.location.pathname.replace("/", "");
    return (
      <>
        <div className="signup-type">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <Link
                className="navbar-brand"
                to="/"
                style={{ cursor: "pointer" }}
              >
                <img src="./../assets/images/homepagelogo.png" alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto navigation">
                  <li
                    className={`nav-item ${
                      activeUrl === "login" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="nav-link"
                      to="/login"
                      onClick={() => this.selectTab("login")}
                    >
                      Login
                      <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className={`nav-item`}>
                    <a
                      className="nav-link"
                      href="https://support.nxa.tel/portal/"
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            {activeUrl === "login" && <Login {...this.props} />}
            {activeUrl === "signup" && (
              <Signup
                {...this.props}
                selectSubTab={this.selectSubTab}
                subTab={subTab}
              />
            )}
            {activeUrl.includes("registration") && (
              <Registration {...this.props} />
            )}
            {activeUrl.includes("reset-password") && (
              <ResetPassword {...this.props} />
            )}
          </div>
        </div>
        <Loader />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nxatel: state?.users ?? [],
  };
};

export default connect(mapStateToProps)(LoginSignup);
