import React, { Component } from "react";
import PageHeader from "./PageHeader";
import SimpleReactValidator from "simple-react-validator";
import ReactTooltip from "react-tooltip";
import Fade from "@material-ui/core/Fade";
import { FetchForgotEmail } from "../../Services/Actions/LoginSignupActions";
import { withRouter } from "../../Utils/withRouter";
import { store } from "../../Services";
import Loader from "../../Component/Loader";
import Footer from "../../Layout/Footer";

class ForgotEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      phone: "",
      clicked: false,
      pageType: "forgot-email",
      getEmail: "",
      emailTooltipText: "Copy Email to clipboard",
    };
    this.validator = new SimpleReactValidator();
    this.emailRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ checked: true });
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  copyEmail = () => {
    const { current } = this.emailRef;
    this.copyToClipboard(current.innerHTML);
    this.setState({ emailTooltipText: "Email copied" });
  };

  copyToClipboard = (innerHTML) => {
    const textField = document.createElement("textarea");
    textField.innerText = innerHTML;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  SubmitHandler = async () => {
    this.setState({ clicked: true });
    if (!this.validator.allValid()) {
      this.setState({ clicked: false });
      this.validator.showMessages();
      this.forceUpdate();
      return null;
    }
    const { phone } = this.state;
    const response = await FetchForgotEmail({ phone : phone.replace(/[^0-9]/g, "") }, store.dispatch);
    if (response?.code === 1) {
      this.setState({
        clicked: false,
        pageType: "copy-email",
        getEmail: response?.data?.email,
      });
    } else {
      this.setState({ clicked: false });
    }
  };

  render() {
    const { phone, clicked, pageType, getEmail, emailTooltipText, checked } =
      this.state;
    this.validator.purgeFields();
    return (
      <>
        <div className="forget-password-type signup-type">
          <div className="container">
            <PageHeader activeClass="login" />
            <div className="row">
              <div className="col-md-6">
                <div className="sign-left-part sign-left-part-vector-img sign-left-align-center pt-5">
                  <img src="./../assets/images/signup/2.png" alt="1" />
                </div>
              </div>
              <div className="col-md-1"></div>
              {
                <Fade in={checked} timeout={500}>
                  <div className="col-md-5">
                    <div className="sign-right-part pt-5">
                      <h1 className="font-36 color-191b fw-700 text-center mt-4">
                        Forgot Email
                      </h1>
                      <p className="color-566E font-16 mb-4 text-center">
                        Don't worry. Finding your Email is easy, just tell us
                        Mobile Number you registered with Nxatel
                      </p>
                      {pageType === "forgot-email" && (
                        <>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="form-group mb-3">
                                <input
                                  type="phone"
                                  className="form-control "
                                  placeholder="Phone"
                                  name="phone"
                                  value={phone}
                                  onChange={this.onChangeHandler}
                                />
                                {this.validator.message(
                                  "Phone",
                                  phone,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center col-md-10 offset-md-1 p-0 otp-verify-btn">
                            <button
                              type="button"
                              className="btn btn-new-sign bg-back  mr-3"
                              onClick={() => this.props.navigate("/login")}
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              className="btn btn-new-sign"
                              onClick={this.SubmitHandler}
                              disabled={clicked}
                            >
                              {clicked ? "Please wait..." : "Submit"}
                            </button>
                          </div>
                        </>
                      )}
                      {pageType === "copy-email" && (
                        <Fade in={true} timeout={500}>
                          <div className="card forgot-email-card">
                            <div className="card-header">
                              <h5>
                                Your Email :{" "}
                                <span ref={this.emailRef}>{getEmail}</span>{" "}
                              </h5>
                              <i
                                className="fas fa-copy"
                                data-tip
                                data-for="meetingId"
                                onClick={this.copyEmail}
                              ></i>
                              <ReactTooltip id="meetingId" type="dark">
                                <span>{emailTooltipText}</span>
                              </ReactTooltip>
                            </div>
                          </div>
                        </Fade>
                      )}
                    </div>
                  </div>
                </Fade>
              }
            </div>
          </div>
        </div>
        <Loader />
        <Footer />
      </>
    );
  }
}

export default withRouter(ForgotEmail);
