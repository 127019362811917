import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import GoogleLogin from "react-google-login";
import Fade from "@material-ui/core/Fade";
import { Link } from "react-router-dom";
import { withRouter } from "../../Utils/withRouter";
import {
  FetchEmailFromToken,
  LoginFxn,
  VerifyExtension,
} from "../../Services/Actions/LoginSignupActions";
import { store } from "../../Services";
import { LINKEDIN_WEB_LOGIN_URL } from "../LinkedIn/parameters";
import { REACT_APP_GOOGLE_APP_ID } from "../../Utils/Common";
import { randomString } from "../../Utils/Common";

const initialState = {
  email: "",
  new_password: "",
  confirm_password: "",
  click: false,
  openForgotModal: false,
  fbLogin: false,
  accessCode: "",
  accessState: "",
  checked: false,
  token: "",
};

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({
      messages: {
        in: "Passwords need to match!",
      },
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ checked: true });
    let path = window.location.pathname.split("/");
    let token = path && path[2];
    this.setState(
      {
        accessState: randomString(15, "aA#"),
        token,
      },
      async () => {
        const { token } = this.state;
        let res = await FetchEmailFromToken({ token }, store.dispatch);
        if (res?.code === 1) {
          this.setState({ email: res?.email });
        }
      }
    );
  }
  onEnterPress = (e) => {
    if (e.keyCode === 13) {
      this.submitHandler();
    }
  };

  _facebookLogin = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status == "connected") {
        this.getCurrentFacebookUserInfo(response);
      } else {
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              this.getCurrentFacebookUserInfo(response);
            }
          },
          { scope: "email" }
        );
      }
    });
  };

  getCurrentFacebookUserInfo() {
    window.FB.api("/me?fields=id,email,name", (userInfo) => {
      this._loginViaSocialSignup(userInfo?.email);
      if (Object.keys(userInfo).length) {
        window.FB.logout((response) => {
          console.log("logout response", response);
          // Person is now logged out
        });
      }
    });
  }

  responseGoogle = async (data) => {
    this._loginViaSocialSignup(data?.profileObj?.email);
  };

  _loginViaSocialSignup = async (email) => {
    if (email) {
      const data = { email, request_from: "web", social: "Y", token: true };
      const response = await LoginFxn(data, store.dispatch);
      if (response.ResponseCode === "1") {
        this.setState({ initialState });
      }
    }
  };

  submitHandler = async () => {
    const { email, confirm_password, new_password, token } = this.state;
    if (this.validator.allValid()) {
      this.setState({ click: true });
      let response;
      let params = {
        newpassword: new_password,
        confirmpassword: confirm_password,
        token,
      };
      response = await VerifyExtension(params, store.dispatch);
      if (response === 1) {
        let data = {
          email,
          password: confirm_password,
          request_from: "web",
          token: true,
        };
        response = await LoginFxn(data, store.dispatch);
        if (response) {
          this.setState({ initialState });
        }
      }
    } else {
      this.setState({ click: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  closeForgotModal = () => {
    this.setState({ openForgotModal: false });
  };

  render() {
    this.validator.purgeFields();
    const { email, new_password, confirm_password, checked, click } =
      this.state;
    return (
      <>
        <Fade in={checked} timeout={500}>
          <div className="row">
            <div className="col-md-5">
              <div className="sign-left-part sign-left-first-page  pt-5">
                <img src="./../assets/images/signup/login-img.png" alt="1" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="sign-right-part pt-5">
                <h1 className="font-36 color-191b fw-700 text-center mb-5">
                  Welcome, Join your team! Complete registration
                </h1>
                <div className="welcome-complete-reg">
                  <div className="form-group mb-3 mb-1">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Email"
                      aria-label="full Name"
                      aria-describedby="basic-addon1"
                      name="email"
                      value={email}
                      onChange={(e) =>
                        this.setState({
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    {this.validator.message(
                      "Email",
                      email,
                      "required|email|max:50,string"
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      className="form-control "
                      placeholder="New Password"
                      aria-label="full Name"
                      aria-describedby="basic-addon1"
                      name="new_password"
                      value={new_password}
                      onChange={(e) =>
                        this.setState({
                          [e.target.name]: e.target.value,
                        })
                      }
                      onKeyDown={(e) => this.onEnterPress(e)}
                    />
                    {this.validator.message(
                      "New Password",
                      new_password,
                      "required|min:6,string"
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      className="form-control "
                      placeholder="Confirm Password"
                      aria-label="full Name"
                      aria-describedby="basic-addon1"
                      name="confirm_password"
                      value={confirm_password}
                      onChange={(e) =>
                        this.setState({
                          [e.target.name]: e.target.value,
                        })
                      }
                      onKeyDown={(e) => this.onEnterPress(e)}
                    />
                    {this.validator.message(
                      "Confirm Password",
                      confirm_password,
                      `required|in:${new_password}`
                    )}
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-new-sign bg-back  mr-3"
                      onClick={() => this.props.navigate("/")}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-new-sign"
                      onClick={this.submitHandler}
                      disabled={click}
                    >
                      {click ? "Loading..." : "Sign in"}
                    </button>
                  </div>

                  <h6 className="color-191b font-18 text-center mt-3 mb-3 fw-400">
                    or Sign in with
                  </h6>
                  <div className="d-flex login-with-icons justify-content-center">
                    <img
                      src="./../assets/images/signup/facebook.png"
                      alt="1"
                      onClick={this._facebookLogin}
                      style={{ cursor: "pointer" }}
                    />
                    <GoogleLogin
                      clientId={REACT_APP_GOOGLE_APP_ID}
                      render={(renderProps) => (
                        <a
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <img
                            src="./../assets/images/signup/google.png"
                            alt="1"
                          />
                        </a>
                      )}
                      onSuccess={this.responseGoogle}
                      onFailure={this.responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                    <a href={LINKEDIN_WEB_LOGIN_URL}>
                      <img
                        src="./../assets/images/signup/linkedin.png"
                        alt="1"
                      />
                    </a>
                  </div>
                  <div className="form-check text-center mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label color-191b font-14 fw-700"
                      for="exampleCheck1"
                    >
                      Stay signed with
                    </label>
                  </div>
                  <p className="by-sign-in font-10 color-ABB7">
                    By signing in, you agree to the Terms of Service and Privacy
                    Notice.BackSign InForgot Password?
                  </p>
                  <p className="text-center" style={{ cursor: "pointer" }}>
                    <Link
                      to="/forgot-password"
                      className="font-10 color-6666 fw-500"
                    >
                      Forgot Your Password?
                    </Link>
                    &nbsp;&nbsp;
                    <Link
                      to="/forgot-email"
                      className="font-10 color-6666 fw-500"
                    >
                      Forgot Your Email?
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </>
    );
  }
}

export default withRouter(Registration);
