import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Privacy extends Component {
    constructor(props) {
        super(props); 
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <>
            <div className="privacy-page-banner">
                <h1>Privacy Policy</h1>
                <div className="privacy-respect-box">
                <div className="container">
                        <div className="privacy-respect-bx-inner">
                            <div className="privacy-left">
                                <h2>We respect your <br />privacy, here's how</h2>
                                <p>This document is to explain how we handle data<br /> and information collected from you while using<br /> our website and/or our services.</p>
                                <Link to="#" className="read-more-privacy">Read More</Link>
                            </div>
                            <div className="privacy-right">
                            <img src="./../assets/images/privacy-banner-img.png" alt="1" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="privacy-content">
                <div className="container">
                    <h5>BY ACCESSING AND USING THIS WEBSITE AND/OR OUR SERVICES, THE USER IS PROVIDING HIS/HER EXPRESS AND VOLUNTARY CONSENT TO THE PROCESSING OF THEIR PERSONAL INFORMATION BY AFRIHOST (“THE COMPANY’) ON THE BASIS SET OUT IN THIS PRIVACY NOTICE. IF THE USER DOES NOT CONSENT, THE USER MUST IMMEDIATELY STOP ACCESSING AND/OR USING THIS WEBSITE AND OUR SERVICES.</h5>
                    <p>
                    1. Introduction 

                        1.1 This Privacy Policy is to be read as if specifically incorporated into the Terms of Service, available at: https://nxa.tel/terms-of-service/. <br/>

                        2. Privacy Policy <br/>

                        2.1 For the purposes of this section, Personal Information will be understood in accordance with the definition provided in the Protection of Personal Information Act 4 of 2013 (“the Act”) and in accordance with the definition of Personal Data in Article 4(1) of the General Data Protection Regulation GDPR (EU) 2016/679. Nxatel also subscribes to the principles for electronically collecting personal information outlined in the Act, and the further legislation referred to therein. We endeavour to ensure the quality, accuracy and confidentiality of Personal Information in our possession. <br/>

                        2.2 In adopting this Privacy Policy, we wish to balance our legitimate business interests and your reasonable expectation of privacy. Accordingly, we will take all reasonable steps to prevent unauthorised access to, or disclosure of your Personal Information. However, it is impossible to guarantee that your Personal Information shall be 100% secure. <br/>

                        2.3 Companies (as defined in the Terms of Service) will be prompted to register an account with Nxatel. In so doing, Companies may be asked to provide the following information (Personal Information): <br/>

                        2.3.1 First name; <br/>

                        2.3.2 Surname; <br/>

                        2.3.3 Email; <br/>

                        2.3.4 Physical address; <br/>

                        2.3.5 Phone number; <br/>

                        2.3.6 Bank account details; <br/>

                        2.3.7 Company / CC / business name; <br/>

                        2.3.8 VAT Number, Postal Address; and <br/>

                        2.3.9 Company/CC/business registration number. <br/>

                        2.3.10 ID Copy <br/>

                        2.3.11 RICA Documents <br/>

                        2.6 We will attempt to limit the types of Personal Information we process to only that to which you consent to (for example, in the context of online registration, newsletters, message boards, surveys, polls, professional announcements, SMS, MMS and other mobile services), but, to the extent necessary, your agreement to these this Privacy Policy constitutes your consent as contemplated in section 69 of the Act and/or Article 7 of the GDPR (whichever is applicable). Where necessary, we will seek to obtain your specific consent in future instances should we deem same required by law and where your consent herein might not be lawfully sufficient. <br/>

                        2.7 All payment information will be captured through the Website. Further, Nxatel shall retain payment information on behalf of its users. <br/>

                        2.8 We will not collect, use or disclose sensitive information (such as information about racial or ethnic origins or political or religious beliefs, where relevant) except with your specific consent or in the circumstances permitted by law. <br/>

                        2.9 By agreeing to the terms contained in this Privacy Policy, you consent to the use of your Personal Information in relation to: <br/>

                        2.9.1 The provision and performance of the services; <br/>

                        2.9.2 Informing you of changes made to our website; <br/>

                        2.9.3 The provision of marketing related services to you by Nxatel should you be a Browser or a User; <br/>

                        2.9.4 Responding to any queries or requests you may have; <br/>

                        2.9.5 Developing a more direct and substantial relationship with users for the purposes described in this clause; <br/>

                        2.9.6 Developing an online user profile; <br/>

                        2.9.7 Understanding general user trends and patterns so that we can develop and support existing and ongoing marketing strategies; <br/>

                        2.9.8 For security, administrative and legal purposes; and <br/>

                        2.9.9 The creation and development of market data profiles which may provide insight into market norms, practices and trends to help us improve our offering to you. Such information will be compiled and retained in aggregated form, but shall not be used in any way which may comprise the identity of a user. <br/>

                        2.10 Although absolute security cannot be guaranteed on the internet, Nxatel has in place up-to-date, reasonable technical and organisational security measures to protect your Personal Information against accidental or intentional manipulation, loss, misuse, destruction or against unauthorised disclosure or access to the information we process online. <br/>

                        2.11 While we cannot ensure or warrant the security of any Personal Information you provide us, we will continue to maintain and improve these security measures over time in line with legal and technological developments. <br/>

                        2.12 We store your Personal Information directly, or alternatively, store your Personal Information on, and transfer your Personal Information to, a central database. If the location of the central database is located in a country that does not have substantially similar laws which provide for the protection of Personal Information, we will take the necessary steps to ensure that your Personal Information is adequately protected in that jurisdiction by means of ensuring that standard data protection clauses are enforced as envisaged by the GDPR. <br/>

                        2.13 Your information will not be stored for longer than is necessary for the purposes described in these Terms or as required by applicable legislation. <br/>

                        2.14 The Personal Information Nxatel collects from users shall only be accessed by Nxatel employees, representatives and consultants on a need-to-know basis, and subject to reasonable confidentiality obligations binding such persons. We will not share any of your Personal Information with third parties. <br/>

                        2.15 Nxatel shall have the right, but shall not be obliged, to monitor or examine any information and materials including any website link that you publish or submit to Nxatel for publishing on the Site. You shall be solely responsible for the contents of all material published by yourself. <br/>

                        2.16 We constantly review our systems and data to ensure the best possible service to our users. We do not accept any users, or representatives of users, under 18 years of age or who otherwise does not have the relevant capacity to be bound by this Privacy Policy. <br/>

                        2.17 We will not sell, share, or rent your Personal Information to any third party or use your e-mail address for unsolicited mail. Any emails sent by Nxatel will only be in connection with the provision of our services and/or the marketing thereof. <br/>

                        3. Your Rights <br/>

                        3.1 A user has the right: <br/>

                        3.1.1 of access to their Personal Information stored and processed by us. This may be done by emailing us at the address referred to in clause 8.8 below; <br/>

                        3.1.2 to rectification of Personal Information that we hold on a user’s behalf, in instances where such Personal Information is incorrect or incomplete; <br/>

                        3.1.3 of erasure of Personal Information (“right to be forgotten”) if such information is no longer needed for the original processing purpose, alternatively if a user withdraws their consent and there is no other reason or justification to retain such Personal Information, further alternatively, if a user has objected to such Personal Information being processed and there is no justified reason for the processing of such Personal Information; <br/>

                        3.1.4 to restrict/suspend processing of Personal Information to only that which is strictly necessary for us to perform our services to you; <br/>

                        3.1.5 if we breach any of the terms of this Privacy Policy, to complain to the requisite data protection authority in their territory; <br/>

                        3.1.6 to withdraw their consent at any time, if processing of Personal Information is based on consent; <br/>

                        3.1.7 to object to processing of Personal Information, if such processing is based on legitimate interests; and <br/>

                        3.1.8 to object to the processing of Personal Information for direct marketing purposes. <br/>

                        4. What Personal Information is Collected <br/>

                        4.1 Such purposes may include the following: <br/>

                        4.1.1. Personal information - such as your name, date of birth, home language, address, account information and email address). <br/>

                        4.1.2. Your contact with us - such as a note or recording of a call you make to our call centre, an email you send to us or other records of any contact you have with us. <br/>

                        4.1.3. Account information - such as product type, dates of payment owed and received, top up information or any other information related to your account. <br/>

                        4.1.4. Financial information - such as bank account details for debit orders. <br/>

                        4.1.5. The supply of personal information by the User to THE COMPANY is voluntary and not mandatory. However, if the User refuses to supply any personal information, certain consequences may naturally flow from such a refusal, such as preventing THE COMPANY from concluding or performing any contract with the User, or preventing THE COMPANY from complying with one or more of its obligations in law. <br/>

                        4.1.6. There are certain laws which require or authorise THE COMPANY to collect a User’s personal information, including RICA, FICA and directives issued under this Act. <br/>

                        5. Log Files <br/>

                        5.1 When you visit Nxatel, even if you do not create an account, we may collect information, such as your IP address, the name of your ISP (Internet Service Provider), your browser, the website from which you visit us, the pages on our website that you visit and in what sequence, the date and length of your visit, and other information concerning your computer’s operating system, language settings, and broad demographic information. This information is aggregated and anonymous data and does not identify you specifically. However, you acknowledge that this data may be able to be used to identify you if it is aggregated with other Personal Information that you supply to us. This information is not shared with third parties and is used only within Nxatel on a need-to-know basis. Any individually identifiable information related to this data will never be used in any way different to that stated above, without your explicit permission. <br/>

                        6. Cookies <br/>

                        6.1 “Cookies” are small text files transferred by a web server to a User’s hard drive and thereafter stored on their computer. The types of information a Cookie collects includes a User’s username, the date and time of their visits to the website, their browsing history and preferences. <br/>

                        6.2. THE COMPANY may use Cookies on this website to: <br/>

                        6.2.1. distinguish one User from another on the website; <br/>

                        6.2.2. remember the User’s last session when they return to the website; <br/>

                        6.2.3. estimate the website's audience size and usage patterns; <br/>

                        6.2.4. store information about the User’s preferences, which allows THE COMPANY to customise the website and content according to the Users individual preferences; and <br/>

                        6.2.5. speed up searches on the website. <br/>

                        6.3. The provisions of this clause are only applicable to Cookies used by THE COMPANY. In some instances, third-party service providers may use Cookies on the website. THE COMPANY cannot and does not control or access Cookies used by third party service providers and takes no responsibility therefor. <br/>

                        6.4. The User has the right and ability to either accept or decline the use of Cookies on their computer’s web browser, whether they are logged in as a member, or simply casually visiting the website. However, declining the use of Cookies may limit a User’s access to certain features on the website. <br/>

                        7. Links from Nxatel <br/>

                        7.1 Nxatel, and the services available through the Website, may contain links to other third party websites, including (without limitation) social media platforms, payment gateways, appointment scheduling and/or live chat platforms (“Third Party Websites”). If you select a link to any Third Party Website, you may be subject to such Third Party Website’s terms and conditions and/or other policies, which are not under the control, nor responsibility, of Nxatel. <br/>

                        7.2 Hyperlinks to Third Party Websites are provided “as is”, and Nxatel does not necessarily agree with, edit or sponsor the content on Third Party Websites. <br/>

                        7.3 Nxatel does not monitor or review the content of any Third Party Website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and we should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of other websites, either. <br/>

                        7.4 Users should evaluate the security and trustworthiness of any Third Party Website before disclosing any personal information to them. Nxatel does not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information. <br/>

                        8. Disclosure and Sharing of Personal Information <br/>

                        8.1 THE COMPANY may, in the course of providing any content or services on this website, or for the purposes of concluding or performing any other services or transaction with a User, share certain personal information with third party operators who perform certain processing activities on behalf of THE COMPANY. <br/>

                        8.2 The information shared and the categories of third party operators with whom it is shared will always be notified to you prior to being shared. Notwithstanding the aforegoing, you consent to us sharing your personal information with the following operators: <br/>

                        8.2.1 Partners or agents involved in delivering the products and services to the User. <br/>

                        8.2.2 Partners or agents that conduct network performance, client satisfaction surveys and any other surveys related to the products or services provided to the User. <br/>

                        8.2.3 Law enforcement agencies, regulatory organisations, courts or other public authorities if THE COMPANY have to, or are authorised to by law. <br/>

                        8.2.4 THE COMPANY will release information if it’s reasonable for the purpose of protecting it against fraud, defending its rights or property, or to protect the interests of it’s Users. <br/>

                        8.2.5 If THE COMPANY is reorganised or sold to another organisation, it may transfer any personal information it holds about the User to that organisation. <br/>

                        8.3 THE COMPANY may also share aggregated information about Users of this website and their usage patterns. Such aggregated information will be de-identified and the User’s personal information will not be disclosed. <br/>

                        8.4 Other than as stated in clause 9.1 and 9.3, THE COMPANY shall not share a User’s personal information with any third parties unless it has the User’s express consent to do so. <br/>

                        9. User's Rights in Relation to the Processing of their Personal Information <br/>

                        9.1 Users shall have the following rights in relation to the processing of their personal information: <br/>

                        9.1.1 to access and correct any personal information held by THE COMPANY about them; <br/>

                        9.1.2 to object to the processing of their information; and <br/>

                        9.1.3 to lodge a complaint with the Information Regulator (https://justice.gov.za/inforeg/). <br/>

                        9.2 Users may make a request in terms of clause 10.1.1 by following the process for making such a request as set out in THE COMPANY’s PAIA manual. <br/>

                        10. Application Of The Electronic Communications And Transactions Act 25 Of 2002 ("Ect Act") <br/>

                        10.1 Data Messages (as defined in the ECT Act) will be deemed to have been received by Nxatel if and when Nxatel responds to the Data Messages. <br/>

                        10.2 Data Messages sent by Nxatel to a user will be deemed to have been received by such user in terms of the provisions specified in section 23(b) of the ECT Act. <br/>

                        10.3 Users acknowledge that electronic signatures, encryption and/or authentication are not required for valid electronic communications between users and Nxatel. <br/>

                        10.4 Information to be provided in terms of section 43(1) of the ECT Act: <br/>

                        10.4.1 Users warrant that Data Messages sent to Nxatel from any electronic device, used by such user, from time to time or owned by such user, were sent and or authorised by such user, personally. <br/>

                        10.4.2 This Website is owned and operated by Nxatel (Pty) Ltd, (Registration Number: 2016/450214/07) a private company owned and operated in accordance with the laws of the Republic of South Africa. <br/>

                        10.5 Address for service of legal documents: 150 Frikkie De Beer St, Glen Manor Office Park, Menlyn, Pretoria, Gauteng, South Africa, 0063. <br/>

                        10.6 Contact Number: +27(0) 12 111 7603. <br/>

                        10.7 Nxatel – located at www.nxa.tel. <br/>

                        10.8 Email address: privacy@nxa.tel  

                    </p>
                </div>
            </div> 
            </>
        );
    }
}

export default Privacy;