

export const REACT_APP_LINKEDIN_APP_ID = "78cqir2karm6a5";
export const REACT_APP_LINKEDIN_APP_SECRET = "DV8zq0QFnb6oGg21";
export const LINKEDIN_ACCESS_TOKEN_API = "https://www.linkedin.com/oauth/v2/accessToken";

// linkedin web link URL

export const LINKEDIN_WEB_SIGNUP_URL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${REACT_APP_LINKEDIN_APP_ID}&redirect_uri=${process.env.REACT_APP_WEBSITE_URL}signup&state=${`Signup`}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;

export const LINKEDIN_WEB_LOGIN_URL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${REACT_APP_LINKEDIN_APP_ID}&redirect_uri=${process.env.REACT_APP_WEBSITE_URL}login&state=${`Login`}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
