import * as constants from "../Constants/userConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert";

export const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 || response.status === 400) {
        localStorage.removeItem("token");
        window.location.href = "/";
      }
      const error =
        (data && data.message) ||
        (data && data.ResponseText) ||
        (data && data.ExceptionMessage) ||
        (data && data.Message) ||
        response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
};

// SHOW LOADER ACTIONS

export function ShowLoader(value, dispatch) {
  dispatch({ type: constants.SHOW_LOADER, payload: value });
}

export const toastr = (data, dispatch) => {
  dispatch({ type: constants.TOASTR, payload: data });
  return 1;
};

export const HomePageSubPages = (data, dispatch) => {
  dispatch({ type: constants.SUB_PAGES, payload: data });
};

export const HomePageSubTabs = (data, dispatch) => {
  dispatch({ type: constants.SUB_TABS, payload: data });
};

// GET USER COUNTRY ACTIONS

export const getUserCountry = (dispatch) => {
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${ACTIONS.GET_USER_COUNTRY}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.FETCH_USER_COUNTRY_DETAILS,
        payload: data.data,
      });
      return 1;
    })
    .catch((error) => {
      dispatch({
        type: constants.FETCH_USER_COUNTRY_DETAILS,
        payload: {},
      });
      return 0;
    });
};

// GET CURRENCY LIST ACTION
export const CurrencyListing = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${ACTIONS.CURRENCY_LIST}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.FETCH_CURRENCY_LISTING,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        dispatch({
          type: constants.FETCH_CURRENCY_LISTING,
          payload: [],
        });
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

// GET PLAN LIST ACTION

export const PlansList = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${ACTIONS.PLANS_LIST}`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.PLANS_LIST,
          payload: data.data,
        });
        return 1;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

export const SelectedPlanDuringSignup = (data, dispatch) => {
  dispatch({ type: constants.SELECTED_PLAN_DURING_SIGNUP, payload: data });
};

// SIGNUP FORM ACTIONS

export const IsSocialSignupFrom = (params, dispatch) => {  
  dispatch({ type: constants.IS_SOCIAL_SIGNUP_FROM, payload: params });
};

export const SocialSignup = (params, dispatch) => { 
  dispatch({ type: constants.SOCIAL_SIGNUP, payload: params });
};

export const CheckAccount = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const {email,phone,prefix} = params
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${ACTIONS.CHECK_ACCOUNT}`, {
    method: "POST",
    body: JSON.stringify({email,phone,prefix}),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        dispatch({
          type: constants.CHECK_ACCOUNT,
          payload: params,
        });
        return {
          code: 1,
          response: data?.data ?? "",
          token: data?.token,
        };
      } else if (data.ResponseCode === "0" || data.ResponseCode === "2") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    })
    .catch((error) => {
      dispatch({ type: constants.CHECK_ACCOUNT, payload: {} });
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      swal({ title: "Error!", text: error, icon: "error" });
      return 0;
    });
};

//  ZOHO DEMO BOOKING ACTIONS

export const ZohoAccessToken = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}${ACTIONS.ZOHO_ACCESS_TOKEN}`, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return data.data.access_token;
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return false;
      }
    });
};

export const ZohoBookDemoAppointment = (data, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${ACTIONS.ZOHO_BOOK_DEMO_APPOINTMENT}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then((res) => res.json())
    .then((response) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      return response;
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      return 0;
    });
};
