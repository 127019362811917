export const IS_SOCIAL_SIGNUP_FROM = "IS_SOCIAL_SIGNUP_FROM"
export const SOCIAL_SIGNUP         = "SOCIAL_SIGNUP"
export const CHECK_ACCOUNT         = "CHECK_ACCOUNT"
export const SHOW_LOADER           = "SHOW_LOADER"
export const TOASTR                =  "TOASTR"
export const PLANS_LIST            = "PLANS_LIST"
export const SUB_TABS              = "SUB_TABS"
export const SUB_PAGES             = "SUB_PAGES"











export const FETCH_USER_COUNTRY_DETAILS     = "FETCH_USER_COUNTRY_DETAILS"
export const FETCH_CURRENCY_LISTING         = "FETCH_CURRENCY_LISTING"
export const SELECTED_PLAN_DURING_SIGNUP    = "SELECTED_PLAN_DURING_SIGNUP"