import React from "react";

function ProgressBar(props) {
    const {active} = props;
    return (
        <>
            <div className="mt-5 mb-3 text-center">
                <h1 className="font-40 color-191b fw-400 ">
                    Get your business landline instantly
                </h1>
            </div>
            <ul className="step d-flex flex-nowrap">
                <li className={`step-item ${active==="sign-up"?"active":""}`}>
                    <a href={void 0}>
                        Sign up from page
                    </a>
                </li>
                <li className={`step-item ${active==="number-verify"?"active":""}`}>
                    <a href={void 0}>
                        Mobile Authentication-OTP
                    </a>
                </li>
                <li className={`step-item ${active==="create-number"?"active":""}`}>
                    <a href={void 0}>
                        Select Number
                    </a>
                </li>
                <li className={`step-item ${active==="select-package"?"active":""}`}>
                    <a href={void 0}>
                        Package and Payments
                    </a>
                </li>
                <li className={`step-item ${active==="order-page"?"active":""}`}>
                    <a href={void 0}>
                        Verify Account
                    </a>
                </li>
            </ul>
        </>
    );
}

export default ProgressBar;
