import React, { Component } from "react";  
import { Link } from "react-router-dom";

class NxaDocs extends Component {
    constructor(props) {
        super(props);
        
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
    } 

    render() {
        return (
            <>
                <div className="product-message-teamwork product-dox-banner">
                    <div className=" container">
                        <div className="col-md-12">
                            <h2 class="">Team Folders Storage​​</h2>
                            <h3>Collaborate and Work smarter with​</h3>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="product-message-teamwork-left">
                                    <h4 className="">
                                        Share files and folders with clients and
                                        team members quick and easy, with secure
                                        links​
                                    </h4>
                                    <ul className="product-dox-banner-ul">
                                        <li>Store ulimited Call recordings​</li>
                                        <li>
                                            Store unlimited Video recordings and
                                            files​
                                        </li>
                                        <li>
                                            Share and add comments with team
                                            members​
                                        </li>
                                        <li>
                                            Instantly create link and share with
                                            customers ​
                                        </li>
                                    </ul>
                                    <div className="btn-center-home ">
                                        <Link
                                            to="#"
                                            className="btn htw-learn-more mr-2"
                                        >
                                            Get Started
                                        </Link>
                                        <Link to="#" className="link-blue">
                                            Try it Schedule your first meeting
                                            ​​
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="product-message-teamwork-right">
                                    <img
                                        src="./../assets/images/team-folder-storage-img.png"
                                        alt="1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-message-teamwork securely-store-share-file">
                    <div className=" container">
                        <div className="col-md-12">
                            <h2 class="">Securely store and share files​</h2>
                            <h3>
                                No matter the file format, Nxatel keeps your
                                team connected. Start sharing with Nxatel
                                today.​​
                            </h3>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="product-message-teamwork-left">
                                    <h4 className="">
                                        Share and work<br></br> together on all
                                        your files
                                    </h4>
                                    <p>
                                        See why Small Businesses<br></br> chose
                                        Nxatel Cloud Storage.
                                    </p>
                                    <div className="btn-center-home ">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/google.png"
                                                alt="1"
                                            />
                                        </Link>
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/Icons App Store Google play 3.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="product-message-teamwork-right">
                                    <img
                                        src="./../assets/images/store-and-share-img.png"
                                        alt="1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="virtual-business-landline">
                    <div className=" container">
                        <div className="row">
                            <div className="col-md-5 align-items-center d-flex">
                                <h2 class="">
                                    Securely store<br></br> and share files
                                </h2>
                            </div>
                            <div className="col-md-7">
                                <img
                                    src="./../assets/images/share-file.png"
                                    alt="1"
                                    className="w-100"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nxatel-free-seven-days product-internet-free-day">
                    <div className=" container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="nxatel-free-seven-days-left">
                                    <img
                                        src="./../assets/images/try-free-seven-day.png"
                                        alt="1"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="nxatel-free-seven-days-right">
                                    <h3 className="">
                                        Try Nxatel for<br></br> free for 7 Days
                                    </h3>
                                    <p>
                                        Nxatel runs on the device you're{" "}
                                        <br></br>using right now.​
                                    </p>
                                    <p className="mt-4">
                                        Ready to switch to a unified platform?​{" "}
                                    </p>
                                    <div className="btn-center-home ">
                                        <Link
                                            to="/pricing"
                                            className="btn htw-learn-more mr-2" 
                                        >
                                            See Pricing
                                        </Link>
                                        <Link
                                            to="/demo"
                                            className="btn get-demo-red" 
                                        >
                                            Get your Demo
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </>
        );
    }
}

export default NxaDocs;
