import React, { Component } from 'react'; 

class NxaVideoModal extends Component {
    constructor(props) {
        super(props);
        
    }

    componentDidMount(){
        const {openModal} = this.props
        if(openModal){
            window.$("#exampleModalCenter").modal({ show: true });
        }else{
            window.$("#exampleModalCenter").modal("hide");
        }
    } 
    
    render() {
        const {closeModal} = this.props
        return ( 
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content"> 
                        <div class="modal-body home-modal-video">
                            <button
                                type="button"
                                className="close"
                                onClick={() =>closeModal()}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <video width="100%" height="auto" controls autoPlay>
                                <source src="./../assets/videos/Nxatel_App_Launc_video.mp4" type="video/mp4"/>
                            </video>   
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}

export default NxaVideoModal;