import React, { Component,useEffect,useState,useRef } from 'react'
import NxaVideoModal from "./NxaVideoModal";
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import GoogleLogin from "react-google-login"; 
import { connect } from 'react-redux'; 
import SimpleReactValidator from "simple-react-validator"; 

import { withRouter } from '../Utils/withRouter';   
import PhoneInput, {parsePhoneNumber,getCountryCallingCode,} from "react-phone-number-input";
import { CheckAccount, IsSocialSignupFrom, SocialSignup } from '../Services/Actions/userActions'; 
import {  SendOTP } from '../Services/Actions/LoginSignupActions'; 
import { store } from '../Services';
import { LINKEDIN_WEB_SIGNUP_URL } from './LinkedIn/parameters';
import { REACT_APP_GOOGLE_APP_ID } from '../Utils/Common';
const { getName } = require("country-list"); 
 


const crm_links = ["None","amoCRM",    "Copper",    "FLG",    "Freshsales",    "HubSpot",    "Microsoft Dynamics",    "noCRM.io",    "Onpipeline",    "Pipedrive",    "Salesforce",    "sugarCRM",    "TeamGram",    "Zendesk Sell",    "Zoho CRM" ,"Sage One", "Xero Accounting ", "Excel", "Other"];
const initialState = {openModal:false,firstname: "",lastname:'',company: "",email: "",phone: "",password: "",call_number: "",prefix: "",country_code: "",country: "",click: false,accessState:"",crm:"",affiliate_code:"",promotional_code:""};


export const VideoAutoPlay = () => {
    const videoRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false); 

    useEffect(() => {
      if (isVisible) {
        videoRef.current.play();
      } else {
        if (videoRef.current.play) {
          videoRef.current.pause();
        }
      }
    }, [isVisible]);
  
    return (
        <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}> 
            <video width="100%" height="100%" controls loop ref={videoRef}>
                <source src="./../assets/videos/nxatel_dreamers.mp4" type="video/mp4"/>
            </video> 
        </VisibilitySensor>
    );
  };


class InnerHome extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({
            messages: {
                in: "Password and Confirm Password must be same!",
            },
        });
        this.state = initialState;
    }

    componentDidMount(){
        window.scrollTo(0, 0)  
        this.setState({ accessState: window.randomString(15, "aA#") });
    }

    numberChange = (number) => {
        let phoneNumber = number ? parsePhoneNumber(number.toString()) : "";
        let prefix = "";
        let country_code = "";
        let call_number = "";
        let country = "";
        if (phoneNumber) {
            if (phoneNumber.nationalNumber && phoneNumber.country) {
                call_number = phoneNumber.nationalNumber.replace(/^0+/, "");
                country_code = phoneNumber.country;
                country = getName(country_code);
                if (getCountryCallingCode(phoneNumber.country)) {
                    prefix = getCountryCallingCode(phoneNumber.country);
                }
            }
        }
        this.setState({call_number: call_number,prefix: prefix,country_code: country_code,country: country,});
    };

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value});
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13) {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        const validator = this.validator;
        const {
            firstname,
            lastname,
            company,
            email,
            call_number,
            password,
            prefix,
            country, 
            promotional_code,
            affiliate_code,
        } = this.state;
        const data = {
            firstname,
            lastname,
            email,
            password,
            phone: call_number,
            prefix,
            country, 
            company,
            promotional_code,
            affiliate_code,
        };
        if (validator.allValid()) {
            this.setState({ click: true });
            let signup =  await CheckAccount(data,store.dispatch); 
            if (signup.code === 1) { 
                this.setState({ initialState });
                let {account_details: { phone, prefix }, } = this.props;
                if (!phone) {
                    this.props.navigate("/") 
                    return false;
                }
                prefix = prefix.includes("+") ? prefix : `+${prefix}`;
                phone = `${prefix}${phone}`; 
                let response = await SendOTP({ Number: phone },store.dispatch);
                if (response === 1) {
                    this.props.navigate("/verify-number") 
                } else {
                    this.setState({ click: false });
                    return false;
                }  
            } else {
                this.setState({ click: false });
            }
        } else {
            validator.showMessages(); 
            this.forceUpdate();
        }
    };

    responseGoogle = (data) => { 
        let profileObj = data?.profileObj;
        if (profileObj) { 
            SocialSignup(profileObj,store.dispatch);
            IsSocialSignupFrom({from:"nxa-page",with:"google"},store.dispatch);
            this.props.navigate("/signup") 
        }
    };

    _facebookLogin = ()=>{ 
        window.FB.getLoginStatus(response =>{ 
            if (response.status == 'connected') {
                this.getCurrentFacebookUserInfo(response)
            } else {
                window.FB.login(response=> { 
                if (response.authResponse){
                    this.getCurrentFacebookUserInfo(response)
                } 
              }, { scope: 'email' });
            }
          }); 
    }

    getCurrentFacebookUserInfo() {
        window.FB.api('/me?fields=id,email,name', userInfo=>{
            this.setState({
                signUpWith:"facebook"
            },()=>{
                SocialSignup(userInfo,store.dispatch);
                IsSocialSignupFrom({from:"nxa-page",with:"facebook"},store.dispatch);
                this.props.navigate("/signup") 
            }) 
            if (Object.keys(userInfo).length) {
                window.FB.logout((response) => {
                console.log("logout response",response)
                // Person is now logged out
            });
            }
        }); 
    } 

    componentDidUpdate(prevProps){
        const {social_signup:old_social_signup} = prevProps;
        const {social_signup} = this.props; 
        if(social_signup && social_signup!==old_social_signup){
            const {name,email} = social_signup;
            const [firstname,lastname] = name.split(" ");
            this.setState({firstname,lastname,email});
        }
    }

    hidePop = () => {
            let element = document.getElementById("home-call-fixpop");
            element.classList.add("hide");
    } 

    closeModal=()=>{
        this.setState({
            openModal:false
        },()=>{
            window.removeBackdrop(); 
        })  
    }

    render() {
        const {promotional_code,openModal,click,firstname,lastname,company,phone,email,password, call_number,affiliate_code} = this.state;
        const { get_user_country:{country_code = "ZA"} = {} } = this.props; 
        this.validator.purgeFields();
        return (
            <>
            <div 
                className="home-call-fixpop" 
                id="home-call-fixpop"  
            >
                <div className="home-call-fixpop-inner">
                    <span className="callfixpop-cross" onClick={e=>{e.stopPropagation();this.hidePop();}}>
                    <img src={`${'./../assets/images'}/cross-add-modal.svg`} alt="1"/>
                    </span>
                    <span className="callfixpop-img">
                    <img src={`${'./../assets/images'}/home-profile-img.svg`} alt="2"/>
                    </span>
                    <h2>Welcome to Nxatel</h2>
                    <h3>Speak to our sales team</h3>
                    <p>Call us on</p>
                    <h4>+27 87 910 3000</h4>
                </div>
            </div>
            <div className="banner-main-div">
                <div className=" banner-after-img">
                    <div className=" container"> 
                        <div className="row">
                            <div className="col-lg-6 col-md-7">
                                <div className="banner-inner mt-5">
                                    <h1>
                                    We help teams<br></br> communicate simpler<br></br> with customers in a <br></br>single app 
                                    </h1>
                                    <h5> 
                                    Manage Calls, Virtual Meetings, Message and Cloud Documents all in one place​
                                    Fully Enterprise Cloud Platform. No waiting access services instantly, Pay monthly.
                                    </h5> 
                                     <div className="d-flex banner-btn">
                                         <Link
                                            to="#"
                                            className="watch-video-link d-none d-md-block"
                                            onClick={()=>this.setState({openModal:true})}
                                            style={{cursor:'pointer'}}

                                        >
                                            Watch Video​
                                          <i className="fas fa-play ml-2"></i>
                                        </Link>
                                        <Link
                                            to="/pricing"
                                            className="btn  orange-radius active mr-3" 
                                        >
                                            See Pricing
                                        </Link>
                                        <Link
                                            to="/demo"
                                            className="btn orange-radius"  
                                        >
                                            Get your Demo
                                        </Link>
                                        <Link
                                            to="#"
                                            className="watch-video-link d-block d-md-none mt-3"
                                        >
                                             Watch Video​
                                          <i className="fas fa-play ml-2"></i>
                                        </Link>
                                    </div> 
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5">
                                <div className="rectangle"></div>
                                <div className="banner-right">
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/home-banner-img.png`} 
                                        alt="1"
                                    />
                                </div>
                            </div>
                        </div> 
                    </div>
                    </div>
                <div className="banner-bottom-client grey-scale-client">
                    <div className="container">
                        <h3>No contract. No set-up Fees.  One Provider for everything.  Easy to scale.  Work from Home Ready​​</h3>
                        <h4>TRUSTED BY OVER 1000+ COMPANIES</h4>
                        <div className="banner-bottom-client-inner">
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img   
                                        src={`${'./../assets/images'}/home-page-img/13.png`} 
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span>
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/9.png`} 
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span>
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/4.png`}
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span>
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/12.png`}
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span>
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/10.png`} 
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span>
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/5.png`}
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span>
                            <span className="company-logo">
                                <a href={void 0}>
                                    <img 
                                        src={`${'./../assets/images'}/home-page-img/14.png`} 
                                        className="img-fluid"
                                        alt="1"
                                    />
                                </a>
                            </span> 
                        </div>
                    </div>
                </div>
            </div>
            {/* how it works section */}
            <section className="sec-small-bussiness how-it-works-home-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center fw-700">Get your cloud communication system up
                            and running in 3 easy steps </h1>
                            <h3>Choose your business Phone Numbers from 20+ Countries. Start Receiving and Making Calls across the world</h3>
                            <p>
                              No hardware. No downloads. No maintenance cost. Plug-n-Play cloud communication system.​
                            </p> 
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="small-bussiness-box">
                                        <span className="how-it-work-cir-num">1</span>
                                        <h6>
                                           Select New or<br></br> Port Your<br></br> Number.​
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="small-bussiness-box">
                                        <span className="how-it-work-cir-num">2</span>
                                        <h6>Add team <br></br>members.​</h6>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="small-bussiness-box">
                                        <span className="how-it-work-cir-num">3</span>
                                        <h6>Start receiving <br></br>and making calls<br></br> and hosting <br></br>online meetings.​</h6>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="small-bussiness-box">
                                        <span className="how-it-work-cir-num"><small>Grow!</small></span>
                                        <h6>
                                            Monitor  team<br></br> metrics in <br></br>real-time,<br></br> Manage cost.​
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 btn-center-home">
                                <Link 
                                    to="/pricing"
                                    className="btn htw-learn-more mr-2"  
                                >
                                    See Pricing
                                </Link> 
                                <Link
                                    to="/demo"
                                    className="btn get-demo-red"  
                                >
                                    Get your Demo
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* how it works section */}
             {/* why switch to nxatel */}
            <section className="why-nxatel-section why-nxatel-section-get-demo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center fw-700">Why switch to Nxatel?​​​</h1>
                            <h3>You have many choices when it comes to business communications systems. Nxatel offers a unified experience, saving you time and money.​​​</h3>
                            <div className="why-nxatel-bx-div">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="why-nxatel-bx">
                                            <span className="switch-nexa-cir">
                                                <img src={`${'./../assets/images'}/wn-1.svg`} alt="1" />
                                            </span>
                                            <h4>No more implementation nightmares.</h4>
                                            <p>With Nxatel, getting started is painless. There’s no special hardware to install, No waiting period.​</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="why-nxatel-bx">
                                            <span className="switch-nexa-cir">
                                                <img src={`${'./../assets/images'}/wn-2.svg`} alt="1" />
                                            </span>
                                            <h4>No Long-term commitments and flexible.</h4>
                                            <p>With Nxatel there’s no contract and no long-term commitment. Scaling it’s a click away.​​</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="why-nxatel-bx">
                                            <span className="switch-nexa-cir">
                                                <img src={`${'./../assets/images'}/wn-3.svg`} alt="1" />
                                            </span>
                                            <h4>Secure & Reliable </h4>
                                            <p>​Enabling enterprise-grade security for businesses.​</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="why-nxatel-bx">
                                            <span className="switch-nexa-cir">
                                                <img src={`${'./../assets/images'}/wn-4.svg`} alt="1" />
                                            </span>
                                            <h4>No Technician needed to deploy as everything is web-based and  administration is simple.</h4>
                                            <p>​Add users or make system changes on the fly from anywhere. ​</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="why-nxatel-bx">
                                            <span className="switch-nexa-cir">
                                                <img src={`${'./../assets/images'}/wn-5.svg`} alt="1" />
                                            </span>
                                            <h4>No using your personal number for calls and messages.</h4>
                                            <p>Having a business phone numbers creates a more polished, professional appearance and gives you more control over customer relationships.​​​</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="why-nxatel-bx">
                                            <span className="switch-nexa-cir">
                                                <img src={`${'./../assets/images'}/wn-6.svg`} alt="1" />
                                            </span>
                                            <h4>Modern Enterprise features</h4>
                                            <p>that will make your business as well equipped as any big business. ​​​</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
             {/* why switch to nxatel end */}
             
            {/* contact form start */}
            <section className="services-sec sign-up-home-sec">
                <div className="container-fluid">
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row ">
                                    <div className="col-lg-1 d-lg-block d-none"></div>
                                    <div className="col-md-5 col-lg-4 align-self-center center-media">
                                        <div className="">
                                            <h2 className="text-align-start">
                                                Instant Set-Up Get Your Business Phone Number In Just 3 Minutes!​
                                            </h2>
                                            <ul className="get-your-buisess-phone-ul">
                                                <li>Choose a local business number from 20+ countries​</li>
                                                <li>Activate your number instantly​</li>
                                                <li>Unlimited calls domestic only*​</li>
                                                <li>Advanced Reports in real time​</li>
                                                <li>Virtual Meetings, SMS and Chat​</li>
                                                <li>Unlimited Cloud Storage​</li>
                                                <li>Access Nxatel on Web and Mobile app</li>
                                            </ul> 
                                        </div>
                                    </div>
                                    <div className="col-lg-1 d-lg-block d-none"></div>

                                    <div className="col-lg-6 col-md-7  pr-0">
                                        <div className="services-right-box ">
                                            <h2 className="color-33 text-center">
                                                Get Started Now!
                                            </h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                            First Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter First Name"
                                                            name="firstname"
                                                            value={firstname}
                                                            onChange={this.onChangeHandler}
                                                            maxLength="20"
                                                        />
                                                        {this.validator.message("First Name",firstname,"required")}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                            Last Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Last Name"
                                                            name="lastname"
                                                            value={lastname}
                                                            onChange={this.onChangeHandler}
                                                            maxLength="20"
                                                        />
                                                        {this.validator.message("Last Name",lastname,"required")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-md-6">
                                                    <label className="fw-700 color2F4B">
                                                        {/* Work Phone* */}
                                                        Mobile Number*
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <PhoneInput
                                                            name="phone"
                                                            placeholder="Phone Number"
                                                            value={phone}
                                                            onChange={(phone) => this.numberChange(phone)}
                                                            defaultCountry={
                                                                country_code
                                                            } 
                                                        />
                                                        {this.validator.message("Phone",call_number,"required")}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                            Work Email*
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            value={email}
                                                            onChange={this.onChangeHandler}
                                                        />     
                                                        {this.validator.message("Email",email,"required|email|min:10,string|max:60,string")}                                                   
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                            Company Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="company"
                                                            placeholder="Enter Company"
                                                            value={company}
                                                            onChange={this.onChangeHandler}
                                                            maxLength="20"
                                                        />
                                                        {this.validator.message("Company",company,"required|min:2,string|max:50,string")}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                            Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Password"
                                                            name="password"
                                                            value={password}
                                                            onChange={this.onChangeHandler}
                                                            maxLength="20"
                                                        />
                                                        {this.validator.message("Password",password,"required|min:5|max:60")} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                            Affiliate Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="affiliate_code"
                                                            placeholder="Enter Affiliate Code"
                                                            value={affiliate_code}
                                                            onChange={this.onChangeHandler}
                                                            maxLength="20"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="fw-700 color2F4B">
                                                           Promotional Code
                                                        </label> 
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Promotional Code"
                                                            name="promotional_code"
                                                            value={promotional_code}
                                                            onChange={
                                                                this.onChangeHandler
                                                            }
                                                            maxLength="20"
                                                        /> 
                                                    </div>
                                                </div> 
                                            </div>
                                            <div className="homepage-sign-up-form-btn">
                                            <button
                                                type="button"
                                                className="btn btn-get-demo " 
                                                onClick={()=>this.props.navigate("/demo")}
                                            >
                                               Get a Demo
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-sign-up-home"
                                                onClick={this.handleSubmit}
                                                onKeyPress={this.onEnterPress}
                                            >
                                                {click?"Please wait...":"Sign up Now"}
                                            </button>
                                            </div>
                                            <div className="or">
                                                <hr className="w-100"></hr>
                                                <span className="or-inner">
                                                    OR
                                                </span>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <GoogleLogin
                                                            clientId={REACT_APP_GOOGLE_APP_ID}
                                                            render={(renderProps) => (
                                                                <div className="social-btn" style={{cursor:"pointer"}} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                                    <img
                                                                        src= {`${'./../assets/images'}/home-page-img/google.png`} className="img-fluid"
                                                                        alt="1"
                                                                    />
                                                                    Sign Up with G Suite
                                                                </div>
                                                            )} 
                                                            onSuccess={this.responseGoogle}
                                                            onFailure={this.responseGoogle}
                                                            cookiePolicy={"single_host_origin"}
                                                        />
                                                </div>
                                                <div className="col-md-4"> 
                                                    <a 
                                                        href={LINKEDIN_WEB_SIGNUP_URL} 
                                                        className="d-flex align-items-center social-btn"
                                                    >
                                                        <img
                                                            src= {`${'./../assets/images'}/home-page-img/link.png`} 
                                                            alt="1"
                                                        />
                                                        Sign Up with LinkedIn
                                                    </a>  
                                                </div>
                                                <div className="col-md-4">
                                                    <div 
                                                        className="social-btn" 
                                                        style={{cursor:"pointer"}} 
                                                        onClick={this._facebookLogin}
                                                    >
                                                        <img 
                                                            src= {`${'./../assets/images'}/home-page-img/face.svg`}  
                                                            className="img-fluid"
                                                            alt="1"
                                                        />
                                                        Sign Up with Facebook
                                                    </div> 
                                                </div>
                                            </div>
                                            <p className="mt-3 mb-0 terms text-center agree-terms-home">
                                                By signing up,you agree to our{" "}
                                                <a href={void 0}>Terms</a> &{" "}
                                                <a href={void 0}>Privacy Policy</a>{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           {/* contact form end */}
             {/* simple work any size team section */}
            <section className="sec-small-bussiness anysize-team-section how-it-works-home-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center fw-700">Simply works for any size team ​</h1>
                            <h3>Nxatel unifies your business communication seamlessly across multiple devices​</h3>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="any-size-team-box">
                                        <div className="any-size-img">
                                            <img src="./../assets/images/for-sale.png" className="img-fluid" alt="1"/>
                                        </div>
                                        <h4>For Sales Teams</h4>
                                        <p>Set a new standard for sales productivity and performance by switching to a phone system that connects with your CRM.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="any-size-team-box">
                                        <div className="any-size-img">
                                              <img src="./../assets/images/for-support.png" className="img-fluid" alt="1"/>
                                        </div>
                                        <h4>For Support Teams</h4>
                                        <p>Connect your phone system to your Helpdesk system and empower your team to focus on delighting customers.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="any-size-team-box">
                                        <div className="any-size-img">
                                              <img src="./../assets/images/for-start.png" className="img-fluid" alt="1"/>
                                        </div>
                                        <h4>For Start-Ups</h4>
                                        <p>Transform your cell phone into a business communications powerhouse with the Nxatel app. Available for Android and iOS users, it allows you to connect with your customers when you are on the go or working remotely.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 btn-center-home ">
                                <Link to="/pricing" className="btn htw-learn-more mr-2" >See Pricing</Link>
                                <Link to="/demo" className="btn get-demo-red">Get your Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* simple work any size team section */}
            {/* buisness communication system section */}
            <section className="buisness-communication-system">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center fw-700">Business Communication System Features​​</h1>
                            <h3>50+ powerful features designed to empower sales, support teams and  your business customer service processes​​</h3>
                            <div className="buisness-comm-bx-div">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-1.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <h4>Access Local Numbers from 20+ Countries.  </h4>
                                                <p> Activate new numbers in a matter of seconds.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-2.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Stay productive with multiple device support. Use smartphones and tablets to make and receive calls from anywhere.​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-3.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Securely record all calls and store unlimited calls on your NxaBox Axxount​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-4.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Stay in touch even when your office is out of reach by forwarding calls to your mobile number.​</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ------ */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-5.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <h4>Easily assign calls to team mbers to mobile or desk phones with IVR</h4>
                                                <p>your calls correctly with automated greeting and distribution.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-6.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Stay productive from anywhere with HD audio and video conferencing capabilities. Host up to 100 participants with Nxatel​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-7.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Empower team communication in all forms. Send text messages,  and collaborative with instant chats directly from your Nxatel platform.​​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-8.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>With Nxatel Call pop client you get one view of notes, previous chats, and customer details.​​</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ------ */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-9.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Stay in the know with call metrics like average call time, missed call rate, call cost.​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-10.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Give your teammates the option to quickly speak to one another before transferring a live call.​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-11.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Classify your calls with custom tags for easy reference and smart analysis.​​​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="buisness-comm-sys">
                                            <span className="buisness-comm-sys-cir">
                                                <img src="./../assets/images/bs-12.svg" className="img-fluid" alt="1"/>
                                            </span>
                                            <div className="buisness-comm-sys-inner">
                                                <p>Set customized schedules to confirm exactly when each of your numbers are available to receive calls.​</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ----- */}
                            </div>
                            <h5>​Preconfigured and ready to use, if you need them​</h5>
                            <h6>Need IP phones? We’ve got you covered. Shop for IP devices to use with your Nxatel service</h6>
                        </div>
                    </div>
                </div>
            </section>
            {/*  buisness communication systemsection */} 
            <section className="customer-sec our-product"> {/*  after-img */}
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12">
                            <h1 className="text-center">Explore our products​</h1>
                            <h3>Get everything to keep your team and customer conversation on one easy system.​</h3>
                            <div className="row mt-5">
                                <div className="col-lg-3 col-md-6">
                                    <div className="customer-box ">
                                        <div className="customer-inner product-img1 bg-yellow">
                                            <img
                                                src="./../assets/images/home-page-img/product_1.png" className="img-fluid"
                                                alt="1"
                                            />
                                        </div>
                                        <h6>Call</h6>
                                        <p>
                                           Cloud phone system with 50+ robust features
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="customer-box">
                                        <div className="customer-inner product-img2 bg-blue">
                                            <img
                                                src="./../assets/images/home-page-img/product_2.png" className="img-fluid"
                                                alt="1"
                                            />
                                        </div>
                                        <h6>Meet</h6>
                                        <p>
                                           Video meetings solution with HD video and screen sharing
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="customer-box">
                                        <div className="customer-inner product-img3 bg-pink">
                                            <img
                                                src="./../assets/images/home-page-img/product_3.png" className="img-fluid"
                                                alt="1"
                                            />
                                        </div>
                                        <h6>Message</h6>
                                        <p>
                                            SMS and Team messaging seamless collaboration with files, tasks, and more
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="customer-box">
                                        <div className="customer-inner product-img4 bg-gray-d8">
                                            <img
                                                src="./../assets/images/home-page-img/product_4.png" className="img-fluid"
                                                alt="1"
                                            />
                                        </div>
                                        <h6>Store Documents</h6>
                                        <p>
                                           SMS and Team messaging seamless collaboration with files, tasks, and more
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* compliance security section */}
            <section className="compliance-section">
                <div className="container"> 
                    <div className="world-class-div">
                        <div className="word-cls-left">
                            <img src="./../assets/images/made-in-south-africa.svg" className="d-block img-fluid" alt="..."/>
                        </div>
                        <div className="word-cls-right">
                            <h1 className=" fw-700">A proudly African and world class product​</h1>
                            <h3>Nxatel is made with love Locally and has local support. Nxatel proudly competes with the best international Telco SaaS (Software as a Service) companies to offering the best, business communication solution in the market with affordable, flexible packages and world-class support.​​
​​​​                            </h3>
                        </div>
                    </div>
                    <div className="word-class-box-div">
                        <div className="row word-class-box-row">
                            <div className="col-md-6">
                                <div className="compliance-inner-div">
                                    <div className="compliance-inner-right">
                                        <img src="./../assets/images/compliance-img-1.svg" className="d-block img-fluid" alt="..."/>
                                    </div>
                                    <div className="compliance-inner-left">
                                        <h4>BBB-EE Level </h4>
                                        <p className="">Nxatel is a level 1 BEE service provider and our woman led team consists of  young diverse team members.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="compliance-inner-div">
                                    <div className="compliance-inner-right">
                                        <img src="./../assets/images/compliance-img-2.svg" className="d-block img-fluid" alt="..."/>
                                    </div>
                                    <div className="compliance-inner-left">
                                        <h4>Flexible plans, Affordable pricing</h4>
                                        <p className="">We offer our products in ZAR, so you can manage your expenses without the monthly fluctuations of USD billing and the benefit to claim VAT.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----- */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="compliance-inner-div">
                                    <div className="compliance-inner-right">
                                        <img src="./../assets/images/compliance-img-3.svg" className="d-block img-fluid" alt="..."/>
                                    </div>
                                    <div className="compliance-inner-left">
                                        <h4>Our Support is Local and Nxa!  </h4>
                                        <p className="">Our friendly trained customer support representatives are ready and available to assist with any support or training related queries on the Nxatel product.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="compliance-inner-div">
                                    <div className="compliance-inner-right">
                                        <img src="./../assets/images/compliance-img.svg" className="d-block img-fluid" alt="..."/>
                                    </div>
                                    <div className="compliance-inner-left">
                                        <h4>Protection of Personal Information </h4>
                                        <p className="">Nxatel complies with all South African legislation including the Protection of Personal Information (POPI) Act 4 of 2013, and in accordance with the definition of Personal Data in Article 4(1) of the General Data Protection Regulation GDPR (EU) 2016/679</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
           {/* compliance security section */}
            <section className="slide2-sec made-dreamer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h2>
                                Made for the dreamers.​​<br></br>{" "}
                                Like you.
                            </h2>
                            <h6 className="font-16">
                                See Nxatel in action.
                                Discover how the Nxatel platform can help you grow your business like never before.
                            </h6>
                            <a
                                href={void 0}
                                className="btn  orange-radius active mt-2 "
                            >
                                Watch Now
                            </a>
                        </div>
                        <div className="col-md-7">
                            <div className="make-dreamer-video-box">
                                <VideoAutoPlay /> 
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <section className="as-seen-on sec-company  text-center">
                <div className="container">
                    <h1 className="">As Seen on</h1>

                    <div className="d-flex flex-wrap company-logo-main mt-5 justify-content-center">
                        <span className="Seen-as company-logo">
                            <a href={void 0}>
                                <img
                                    src="./../assets/images/home-page-img/seen_1.png" className="img-fluid"
                                    alt="1"
                                />
                            </a>
                        </span>
                        <span className="Seen-as company-logo">
                            <a href={void 0}>
                                <img
                                    src="./../assets/images/home-page-img/seen_2.png" className="img-fluid"
                                    alt="1"
                                />
                            </a>
                        </span>
                        <span className="Seen-as company-logo">
                            <a href={void 0}>
                                <img
                                    src="./../assets/images/home-page-img/seen_3.png" className="img-fluid"
                                    alt="1"
                                />
                            </a>
                        </span>
                        <span className="Seen-as company-logo">
                            <a href={void 0}>
                                <img
                                    src="./../assets/images/home-page-img/seen_4.png" className="img-fluid"
                                    alt="1"
                                />
                            </a>
                        </span>
                        <span className="Seen-as company-logo">
                            <a href={void 0}>
                                <img
                                    src="./../assets/images/home-page-img/seen_5.png" className="img-fluid"
                                    alt="1"
                                />
                            </a>
                        </span>
                        <span className="Seen-as company-logo">
                            <a href={void 0}>
                                <img
                                    src="./../assets/images/home-page-img/seen_6.png" className="img-fluid"
                                    alt="1"
                                />
                            </a>
                        </span>
                    </div>
                </div>
            </section>

            {
                openModal && <NxaVideoModal  openModal={openModal} closeModal={this.closeModal} />
            }
           
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        social_signup:state?.users?.social_signup??{},
        account_details: state?.users?.check_account ?? {} ,
        get_user_country: state?.users?.get_user_country??{},
    }
}

export default  connect(mapStateToProps)(withRouter(InnerHome)) 
