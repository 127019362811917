export const USERPANEL = "userPanel/";
export const ADMINPANEL = "adminPanel/";

export const CHECK_ACCOUNT = "check-account";
export const ZOHO_ACCESS_TOKEN = "zoho-access-token";
export const GET_USER_COUNTRY = "get-user-info";
export const CURRENCY_LIST = "currency-list";
export const PLANS_LIST = "plan-list";
export const ACCOUNT_SUMMARY = "account-summary";


export const ZOHO_BOOK_DEMO_APPOINTMENT =   "https://pay-dev.nxa.tel/api/NxaAPI/ZohoBookingsBookAppointment"