import React, { Component } from 'react'
import SimpleReactValidator from "simple-react-validator";  
import PhoneInput, {parsePhoneNumber} from "react-phone-number-input";  
import GoogleLogin from "react-google-login"; 
import { connect } from 'react-redux'; 
import moment from 'moment'; 
import Fade from "@material-ui/core/Fade";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import swal from 'sweetalert'; 
import { Link } from '@material-ui/core'; 
import { IsSocialSignupFrom, SocialSignup, ZohoAccessToken, ZohoBookDemoAppointment } from '../Services/Actions/userActions';
import { LINKEDIN_WEB_SIGNUP_URL } from './LinkedIn/parameters'; 
import { withRouter } from '../Utils/withRouter'; 
import { REACT_APP_GOOGLE_APP_ID } from '../Utils/Common';
import { store } from '../Services';

const crm_links = ["None","amoCRM","Copper","FLG","Freshsales","HubSpot","Microsoft Dynamics","noCRM.io","Onpipeline","Pipedrive","Salesforce","sugarCRM","TeamGram","Zendesk Sell","Zoho CRM" ,"Sage One","Xero Accounting","Excel","Other"];
const team_size_list = ["1-2","3-10","10-25","26-100","100+"] 
const manage_business_call_list = ["Mobile Phone","PABX System","VoIP System","Direct Landline Connected to phone"] 
const timezone_list = [
    {text:"(GMT +02:00) South Africa Standard Time (Africa/Johannesburg)", value:"Africa/Johannesburg"},
    {text:"(GMT +02:00) South Africa Standard Time (Africa/Maseru)" ,value:"Africa/Maseru"},
    {text:"(GMT +02:00) South Africa Standard Time (Africa/Mbabane)", value:"Africa/Mbabane"}
] 

const initialState = {selectedTimeSlot:'',timezone:'',manage_calls:"",checked:false,selectedDate:new Date(),bookedDate:moment().locale("en").format('l'),pageType:'demo-details',fullname: "",company: "",email: "",phone: "",call_number: "" ,accessState:"",crm:"",team_size:''};

class GetDemoBooking extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.timezone_validator = new SimpleReactValidator();
        this.state = initialState;
    } 


    componentDidMount(){
        window.scrollTo(0, 0);
        this.setState({ accessState: window.randomString(15, "aA#"),checked:true });
    }

    numberChange = (number) => {
        let phoneNumber = number ? parsePhoneNumber(number.toString()) : ""; 
        let call_number = ""; 
        if (phoneNumber) {
            if (phoneNumber.nationalNumber && phoneNumber.country) {
                call_number = phoneNumber.nationalNumber.replace(/^0+/, ""); 
            }
        }
        this.setState({call_number: call_number});
    };

    onChangeHandler = e => {
        this.setState({[e.target.name]:e.target.value});
    } 

    handleSubmit = async () => { 
        const validator = this.validator;  
        if (validator.allValid()) { 
            this.setState({
                pageType : 'date-time-select'
            })
        } else {
            validator.showMessages();
            this.forceUpdate();
        }
    };

    responseGoogle = (data) => {
        let profileObj = data?.profileObj;
        if (profileObj) { 
            SocialSignup(profileObj,store.dispatch);
            IsSocialSignupFrom({from:"nxa-page",with:"google"},store.dispatch);
            this.props.navigate("/signup") 
        }
    }; 

    _facebookLogin = ()=>{ 
        window.FB.getLoginStatus(response =>{ 
            if (response.status == 'connected') {
                this.getCurrentFacebookUserInfo(response)
            } else {
              window.FB.login(response=> { 
                if (response.authResponse){
                    this.getCurrentFacebookUserInfo(response)
                } 
              }, { scope: 'email' });
            }
          }); 
    }

    getCurrentFacebookUserInfo() {
        window.FB.api('/me?fields=id,email,name', userInfo=>{
            this.setState({
                signUpWith:"facebook"
            },()=>{
                 SocialSignup(userInfo,store.dispatch);
                 IsSocialSignupFrom({from:"nxa-page",with:"facebook"},store.dispatch);
                    this.props.navigate("/signup"); 
            }) 
            if (Object.keys(userInfo).length) {
                window.FB.logout((response) => {
                console.log("logout response",response)
                // Person is now logged out
            });
            }
        }); 
    } 
     
    componentDidUpdate(prevProps){
        const {social_signup:old_social_signup} = prevProps;
        const {social_signup} = this.props;
        if(social_signup && social_signup!==old_social_signup){
            const {name,email} = social_signup;
            const [fullname,lastname] = name.split(" ");
            this.setState({fullname,lastname,email});
        }
    } 


    handleDayClick = (day, { selected })=> { 
        this.setState({
            selectedDate: selected ? undefined : day,
            bookedDate:moment(day).locale("en").format('l')
        });
    } 

    bookDemoAppointment =async()=>{
        const {bookedDate,fullname,email,call_number,company,team_size,manage_calls,crm,timezone,selectedTimeSlot} = this.state  
        let params = {
            from_time : `${moment(bookedDate).locale("en").format('YYYY[-]MM[-]D')} ${selectedTimeSlot}`,
            name:fullname,
            email,
            phone_number:call_number,
            timezone,
           "Company Name" : company,
           "Team Size" : team_size,
           "What are you currently using to manage business calls?" : [manage_calls],
           "Which CRM or Ticketing tool do you use?" : [crm]
        }   
        const validator = this.timezone_validator;  
        if (validator.allValid()) {
            if(bookedDate && selectedTimeSlot){
                let access_token = await ZohoAccessToken(store.dispatch);
                if (!access_token) {
                    return false;
                }
                let res = await ZohoBookDemoAppointment({...params,access_token},store.dispatch)
                if(res?.Data?.success){
                    this.setState({
                        pageType:'success-page'
                    })
                } 
            } else{
                swal({title:'Error!',text:'Date and time for scheduling demo is required.',icon:'error'})
            } 
        } else { 
            validator.showMessages();
            this.forceUpdate();
        } 
    }
 
    render() {
        const {selectedTimeSlot,timezone,manage_calls,selectedDate,checked,pageType,fullname,team_size,company,phone,email,call_number,crm,bookedDate} = this.state; 
        const { get_user_country:{country_code = "ZA"} = {} } = this.props; 
        this.validator.purgeFields(); 
        return (
            <>  
                <Fade in={checked} timeout={500}>
                    <React.Fragment>
                    <div className="get-demo-banner-div">
                        {pageType === "demo-details" && 
                            <div className="container-fluid">
                                <div className="pricing-sign-up-form">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row ">
                                                <div className="col-lg-1 d-lg-block d-none"></div>
                                                <div className="col-md-5 col-lg-5 align-self-center center-media">
                                                    <div className="">
                                                    <img src="./../assets/images/sigin-up-pricing-img.png" alt="logo" className="img-fluid" />
                                                        <h2 className="text-align-start">
                                                        Nxatel Product Consultation Includes
                                                        </h2>
                                                        <ul className="get-your-buisess-phone-ul">
                                                            <li>Show you how to simple it is to get Local business number from 20+ countries​</li>
                                                            <li>Virtual Meetings, SMS and Chat​</li>
                                                            <li>Advanced Reports in real time​</li>
                                                            <li>Unlimited Cloud Storage​​</li>
                                                            <li>Access Nxatel on Web and Mobile app​​</li>
                                                        </ul> 
                                                    </div>
                                                </div> 
                                                <div className="col-lg-6 col-md-7  pr-0">
                                                    <div className="services-right-box ">
                                                        <h2 className="color-33 text-center">
                                                            Book a Nxatel demo today!
                                                        </h2>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="fw-700 color2F4B">
                                                                        Full Name*
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter First Name"
                                                                        name="fullname"
                                                                        value={fullname}
                                                                        onChange={this.onChangeHandler}
                                                                    />
                                                                    {this.validator.message("Full Name",fullname,"required|min:5,string|max:20,string")}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fw-700 color2F4B"> 
                                                                    Mobile Number*
                                                                </label>
                                                                <div className="input-group mb-3">
                                                                    <PhoneInput
                                                                        name="phone"
                                                                        placeholder="Phone Number"
                                                                        value={phone}
                                                                        onChange={(phone) => this.numberChange(phone)}
                                                                        defaultCountry={
                                                                            country_code
                                                                        }
                                                                    />
                                                                    {this.validator.message("Phone",call_number,"required")}
                                                                </div>
                                                            </div> 
                                                        </div>
                                                        <div className="row"> 
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="fw-700 color2F4B">
                                                                        Company Name*
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="company"
                                                                        placeholder="Enter Company"
                                                                        value={company}
                                                                        onChange={this.onChangeHandler}
                                                                    />
                                                                    {this.validator.message("Company",company,"required|min:2,string|max:50,string")}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="fw-700 color2F4B">
                                                                        Work Email*
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        name="email"
                                                                        placeholder="Enter Email"
                                                                        value={email}
                                                                        onChange={this.onChangeHandler}
                                                                    />     
                                                                    {this.validator.message("Email",email,"required|email|min:10,string|max:60,string")}                                                   
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="fw-700 color2F4B">
                                                                        Team Size*
                                                                    </label>
                                                                    <select className="form-control" name="team_size" value={team_size} onChange={this.onChangeHandler}>
                                                                        <option value="">Select Team Size</option>
                                                                        {team_size_list.map((value,index)=>{
                                                                            return (
                                                                                <option value={value} key={index}>{value}</option>
                                                                            )
                                                                        })}
                                                                    </select> 
                                                                    {this.validator.message("Team Size",team_size,"required")}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="fw-700 color2F4B">
                                                                        Which CRM or Ticketing tool do you use?*
                                                                    </label>
                                                                    <select className="form-control" name="crm" value={crm} onChange={this.onChangeHandler}>
                                                                        <option value="">Select Option</option>
                                                                        {crm_links.map(value=>{
                                                                            return (
                                                                                <option key={value} value={value}>{value}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {this.validator.message("CRM",crm,"required")} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row"> 
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="fw-700 color2F4B">
                                                                        What are you currently using to manage business calls?*
                                                                    </label>
                                                                    <select className="form-control" name="manage_calls" value={manage_calls} onChange={this.onChangeHandler}>
                                                                        <option value="">Select Option</option>
                                                                        {manage_business_call_list.map(value=>{
                                                                            return (
                                                                                <option key={value} value={value}>{value}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {this.validator.message("Business Calls",manage_calls,"required")} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="homepage-sign-up-form-btn"> 
                                                        <button
                                                            type="button"
                                                            className="btn  btn-get-demo" 
                                                            onClick ={()=>this.props.navigate("/signup")} 
                                                        >
                                                            Sign up Now
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sign-up-home" 
                                                            onClick={this.handleSubmit}
                                                        >
                                                        Get a Demo
                                                        </button>
                                                        </div>
                                                        <div className="or">
                                                            <hr className="w-100"></hr>
                                                            <span className="or-inner">
                                                                OR
                                                            </span>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <GoogleLogin
                                                                        clientId={REACT_APP_GOOGLE_APP_ID}
                                                                        render={(renderProps) => (
                                                                            <div className="social-btn" style={{cursor:"pointer"}} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                                                <img
                                                                                    src="./../assets/images/home-page-img/google.png"
                                                                                    alt="1"
                                                                                />
                                                                                Sign Up with G Suite
                                                                            </div>
                                                                        )} 
                                                                        onSuccess={this.responseGoogle}
                                                                        onFailure={this.responseGoogle}
                                                                        cookiePolicy={"single_host_origin"}
                                                                    />
                                                            </div>
                                                            <div className="col-md-4">   
                                                                <Link 
                                                                    href={LINKEDIN_WEB_SIGNUP_URL}
                                                                    className="d-flex align-items-center social-btn" 
                                                                >
                                                                    <img
                                                                        src="./../assets/images/home-page-img/link.png"
                                                                        alt="1"
                                                                    />
                                                                    Sign Up with LinkedIn
                                                                </Link>   
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div 
                                                                    className="social-btn" 
                                                                    style={{cursor:"pointer"}} 
                                                                    onClick={this._facebookLogin}
                                                                >
                                                                    <img
                                                                        src="./../assets/images/home-page-img/face.svg"
                                                                        alt="1"
                                                                    />
                                                                    Sign Up with Facebook
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="mt-3 mb-0 terms text-center agree-terms-home">
                                                            By signing up,you agree to our{" "}
                                                            <Link to="#">Terms</Link> &{" "}
                                                            <Link to="/privacy">Privacy Policy</Link>{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {
                                pageType === 'date-time-select' && 
                                <Fade in={true} timeout={500}>
                                    <div className="booking-slote-div-web">
                                        <h2>Awesome! Which day and time suits you for a quick 30 min call with our product specialist?</h2>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="slote-calender-div-home"> 
                                                        <DayPicker 
                                                            selectedDays={selectedDate}
                                                            onDayClick={this.handleDayClick}
                                                            disabledDays={[ 
                                                                { 
                                                                    before: new Date( ),
                                                                },
                                                                { daysOfWeek: [0, 6] }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="slote-list-div-home">
                                                        <label>Slot Availability</label>
                                                            <select 
                                                                className="form-control" 
                                                                name="timezone" 
                                                                value={timezone} 
                                                                onChange={this.onChangeHandler}
                                                            >
                                                                <option value="">Select Option</option>
                                                                {timezone_list.map(value=>{
                                                                    return (
                                                                        <option key={value} value={value.value}>{value.text}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {this.timezone_validator.message("Timezone",timezone,"required")} 
                                                            {window.dateandtimearray && window.dateandtimearray.map((value,index)=>{ 
                                                                let currentTime     =   moment().locale('en').format('HH:mm') 
                                                                let isCurrentDate   =   moment(bookedDate).isSame(moment().locale ("en").format('l'));
                                                                let morningTime     =   moment("11:30",'HH:mm').isBefore(moment(currentTime, 'HH:mm'))  
                                                                let afterNoontime   =   moment("15:30",'HH:mm').isBefore(moment(currentTime, 'HH:mm'))
                                                                let eveningtime     =   moment("18:00",'HH:mm').isBefore(moment(currentTime, 'HH:mm'))
                                                                return(
                                                                    <>
                                                                        {
                                                                            isCurrentDate &&  morningTime   && value.day ===  "Morning"   ? '' :
                                                                            isCurrentDate &&  afterNoontime && value.day === "Afternoon" ? '' : 
                                                                            isCurrentDate &&  eveningtime   && value.day === "Evening"   ? '' :
                                                                            <p><span>{value.day}</span></p>
                                                                        } 
                                                                        <div className="slot-group" key="index">
                                                                            {value.slots && value.slots.map((val,i)=>{  
                                                                                let beforeCurrentTime = moment(val._24hrformat,'HH:mm').isBefore(moment(currentTime, 'HH:mm'))  
                                                                                return( 
                                                                                    <Link 
                                                                                        to="#" 
                                                                                        className={`slot-anch ${isCurrentDate && beforeCurrentTime ? 'hide' : ""} ${selectedTimeSlot === val._24hrformat ? 'active' : ''}`} 
                                                                                        onClick={()=>this.setState({selectedTimeSlot:val._24hrformat})}
                                                                                    >
                                                                                        {val.time}
                                                                                    </Link>   
                                                                                )
                                                                            }) }
                                                                        </div>
                                                                    </> 
                                                                )
                                                            })} 
                                                        <div className="col-md-12 text-center">
                                                            <Link 
                                                                className="demo-request-btn" 
                                                                onClick={()=>this.bookDemoAppointment()}
                                                            >  
                                                                Schedule Demo
                                                            </Link>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade> 
                            }
                            {
                                pageType === "success-page" && 
                                <Fade in={true} timeout={500}>
                                    <div className="container-fluid">
                                        <div className="pricing-sign-up-form">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row ">
                                                        <div className="col-lg-1 d-lg-block d-none"></div>
                                                        <div className="col-md-5 col-lg-5 align-self-center center-media">
                                                            <div className="">
                                                            <img src="./../assets/images/sigin-up-pricing-img.png" alt="logo" className="img-fluid" />
                                                                <h2 className="text-align-start">
                                                                Nxatel Product Consultation Includes
                                                                </h2>
                                                                <ul className="get-your-buisess-phone-ul">
                                                                    <li>Show you how to simple it is to get Local business number from 20+ countries​</li>
                                                                    <li>Virtual Meetings, SMS and Chat​</li>
                                                                    <li>Advanced Reports in real time​</li>
                                                                    <li>Unlimited Cloud Storage​​</li>
                                                                    <li>Access Nxatel on Web and Mobile app​​</li>
                                                                </ul> 
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-7  pr-0">
                                                            <div className="services-right-box services-right-box-sign-up ">
                                                                <h2 className="color-33 text-center">Thank you,<br />
                                                                    <span >Your demo is<br />scheduled!</span>
                                                                </h2>
                                                                <h4>A calendar invitation is sent to your email.<br />
                                                                You may cancel or reschedule your meeting  details on email</h4>
                                                                <Link to="/" className="go-to-home-anch">Back to Homepage</Link>
                                                            </div>
                                                        </div> 
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </Fade>
                            }
                        </div>
                        {/* -------- */}
                        <div className="banner-bottom-client get-demo-client grey-scale-client">
                            <div className="container">
                                <h3>No contract. No set-up Fees.  One Provider for everything.  Easy to scale.  Work from Home Ready​​</h3>
                                <h4>TRUSTED BY OVER 1000+ COMPANIES</h4>
                                <div className="banner-bottom-client-inner">
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/13.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/9.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/4.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/12.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/10.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/5.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                    <span className="company-logo">
                                        <Link to="#">
                                            <img
                                                src="./../assets/images/home-page-img/14.png"
                                                alt="1"
                                            />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* ------ */}
                        {/* why switch to nxatel */}
                        <section className="why-nxatel-section why-nxatel-section-get-demo">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="text-center fw-700">Why switch to Nxatel?​​​</h1>
                                        <h3>You have many choices when it comes to business communications systems. Nxatel offers a unified experience, saving you time and money.​​​</h3>
                                        <div className="why-nxatel-bx-div">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="why-nxatel-bx">
                                                        <span className="switch-nexa-cir">
                                                            <img src="./../assets/images/wn-1.svg" alt="1" />
                                                        </span>
                                                        <h4>No more implementation nightmares.</h4>
                                                        <p>With Nxatel, getting started is painless. There’s no special hardware to install, No waiting period.​</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="why-nxatel-bx">
                                                        <span className="switch-nexa-cir">
                                                            <img src="./../assets/images/wn-2.svg" alt="1" />
                                                        </span>
                                                        <h4>No Long-term commitments and flexible.</h4>
                                                        <p>With Nxatel there’s no contract and no long-term commitment. Scaling it’s a click away.​​</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="why-nxatel-bx">
                                                        <span className="switch-nexa-cir">
                                                            <img src="./../assets/images/wn-3.svg" alt="1" />
                                                        </span>
                                                        <h4>Secure & Reliable </h4>
                                                        <p>​Enabling enterprise-grade security for businesses.​</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="why-nxatel-bx">
                                                        <span className="switch-nexa-cir">
                                                            <img src="./../assets/images/wn-4.svg" alt="1" />
                                                        </span>
                                                        <h4>No Technician needed to deploy as everything is web-based and  administration is simple.</h4>
                                                        <p>​Add users or make system changes on the fly from anywhere. ​</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="why-nxatel-bx">
                                                        <span className="switch-nexa-cir">
                                                            <img src="./../assets/images/wn-5.svg" alt="1" />
                                                        </span>
                                                        <h4>No using your personal number for calls and messages.</h4>
                                                        <p>Having a business phone numbers creates a more polished, professional appearance and gives you more control over customer relationships.​​​</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="why-nxatel-bx">
                                                        <span className="switch-nexa-cir">
                                                            <img src="./../assets/images/wn-6.svg" alt="1" />
                                                        </span>
                                                        <h4>Modern Enterprise features</h4>
                                                        <p>that will make your business as well equipped as any big business. ​​​</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* why switch to nxatel end */}

                    </React.Fragment>
                </Fade> 
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        social_signup:state?.users?.social_signup??"",
        get_user_country: state?.users?.get_user_country??{},
    }
}

export default connect(mapStateToProps)(withRouter(GetDemoBooking)); 