import * as constants from "../Constants/userConstants";
import * as ACTIONS from "./../Constants/apiConstants.js";
import swal from "sweetalert";
import { store } from "../../Services";
import { handleResponse, toastr } from "./userActions";
import authService from "../../Utils/authService";

const path =
  window.location.hostname === "localhost"
    ? "http://localhost:3001/login-via-token/"
    : process.env.REACT_APP_PORTAL_LOGIN_URL;

export const UserSignUp = async (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}/user/signup`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({ "Content-Type": "application/json" }),
    }
  );
  const result = await fetch(request);
  const data = await result.json();
  if (data.ResponseCode === "1") {
    if (data.token) {
      window.location.href = `${path}${data.token}`;
    } else {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      localStorage.setItem("token", data.token);
      localStorage.setItem("data", JSON.stringify(data.data));
    }
    return 1;
  } else {
    dispatch({ type: constants.SHOW_LOADER, payload: false });
    swal({
      title: "Error!",
      text: data.ResponseText,
      icon: "error",
    });
    return 0;
  }
};

export const LoginFxn = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}admin/login`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        if (data.token) {
          return (window.location.href = `${path}${data.token}`);
        } else {
          let properties = {
            name: data.data.firstname,
            fullname: `${data.data.firstname} ${data.data.lastname}`,
          };
          localStorage.setItem("token", data.token);
          localStorage.setItem("type", data.data.type);
          localStorage.setItem("customerID", data.data.customerID);
          localStorage.setItem("properties", JSON.stringify(properties));
          localStorage.setItem("data", JSON.stringify(data.data));
          return data;
        }
      } else if (data.ResponseCode === "0") {
        swal({
          icon:"error",
          title:"Error!",
          text:data?.ResponseText
        })
        return data;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const AccountSummary = (dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}${ACTIONS.USERPANEL}${ACTIONS.ACCOUNT_SUMMARY}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": `${localStorage.getItem("token")}`,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, data: data.data };
      } else if (data.ResponseCode === "0") {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return { code: 0 };
      }
    });
};

export const FetchEmailFromToken = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}fetch-email-fromToken `,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, email: data?.email };
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return { code: 0 };
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const VerifyExtension = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}verify-extension `,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const VerifyForgotPassword = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}verify-password-otp`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        if (params.password) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("data", JSON.stringify(data.data));
        }
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const FetchForgotPassword = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}/forgot-password`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const FetchForgotEmail = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}/api-recover-email`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, data: data?.data };
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return { code: 0 };
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const FetchResetPassword = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}/reset-password`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        swal({ title: "Success!", text: data.ResponseText, icon: "success" });
        return 1;
      } else {
        swal({ title: "Error!", text: data.ResponseText, icon: "error" });
        return 0;
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
    });
};

export const AccessViaLinkedin = (params, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}auth-via-linkedin`,
    {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({ "Content-Type": "application/json" }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      if (data.ResponseCode === "1") {
        return { code: 1, response: data.data };
      } else {
        return { code: 0, response: data.ResponseText };
      }
    })
    .catch((error) => {
      dispatch({ type: constants.SHOW_LOADER, payload: false });
      return { code: 0, response: error };
    });
};

// PHONE VERIFICATIONS ACTIONS

export const SendOTP = (data, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}send-otp`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        localStorage.setItem("mbl_token", data?.request_id);
        return 1;
      } else {
        swal({
          title: "Error!",
          text: `${data.ResponseText}`,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      swal({ title: "Error!", text: error, icon: "error" });
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      return 0;
    });
};

export const SendOTPThroughCall = (data, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}send-otp-by-call`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({ "Content-Type": "application/json" }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        localStorage.setItem("mbl_token", data?.request_id);
        return 1;
      } else {
        swal({
          title: "Error!",
          text: `${data.ResponseText}`,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      swal({ title: "Error!", text: error, icon: "error" });
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      return 0;
    });
};

export const VerifyOTP = (data, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(`${process.env.REACT_APP_API_DOMAIN}verify-otp`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        toastr(
          {
            duration: 3000,
            message: "Phone number verified successfully.",
            type: "success",
          },
          store.dispatch
        );
        return 1;
      } else {
        swal({
          title: "Error!",
          text: `${data.ResponseText}`,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      return 0;
    });
};

export const cancelVerificationRequest = (data, dispatch) => {
  dispatch({ type: constants.SHOW_LOADER, payload: true });
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}cancel-verification-request`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({ "Content-Type": "application/json" }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
      if (data.ResponseCode === "1") {
        return 1;
      } else {
        swal({
          title: "Error!",
          text: `${data.ResponseText}`,
          icon: "error",
        });
        return 0;
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: constants.SHOW_LOADER,
        payload: false,
      });
    });
};

export function VerifiedNumber(data, dispatch) {
  const token = authService.getUserAccessToken();
  const request = new Request(
    `${process.env.REACT_APP_API_DOMAIN}userPanel/phone-verification`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      }),
    }
  );
  return fetch(request)
    .then(handleResponse)
    .then((data) => {
      if (data.ResponseCode === "1") {
        return 1;
      } else {
        swal({
          title: "Error!",
          text: data.ResponseText,
          icon: "error",
        });
        return 0;
      }
    });
}
