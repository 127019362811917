import React from 'react'

function ContactBar() {
    return (
        <div className="live-chat  d-flex  align-items-center justify-content-end">
            <span className="mr-4">
                <a href="#">0861 692 835</a>
            </span>
            <span>
                <a className="" href={void 0}>
                    <img
                        src="./../assets/images/signup/live-chat.png"
                        alt="chat"
                    />
                    Live Sales Chat
                </a>
            </span>
        </div>
    )
}

export default ContactBar
